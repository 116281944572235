import styled from 'styled-components';
import { Card, Col, Figure, Row, Alert, Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { Button } from '../../components';

export const PaginationSection = styled.div`
.active>.page-link, .page-link.active {
  background-color:#4FB973;
  border: none;
  z-index: 0;
  display:list-item;
}
.page-item.active .page-link {
  background-color:#4FB973;
  border: none;
}
.page-link:focus {
  box-shadow: none; 
}
 .nextAndPrev{
  display:list-item !important;
}
.page-link{
  color:#7E818F;
  font-size: 12px !important;
  white-space:nowrap;
  border: none;
}
.page-link:hover{
  color:#08c046;
}
.active>.page-link:hover{
  color:white;
}
`;

export const CustomCardClick = styled(Card)`
    cursor: pointer;
    .checkbox{
      right:0px;
      top:10px;
    }
`;
export const CustomCard = styled(Card)`
  border: 1px solid #bfb6b6 !important;
  border-radius:7px !important;
  padding: 0.75rem;

    .fw-normal{
        font-weight: 400;
    }

    .letter-spacing-1{
      letter-spacing: 1px;
    }

    @media  (max-width:768px) {
    padding:0.5rem;

     .card-body{
      padding:0.5rem;
    }
  }
    
`;

export const NameCircle = styled.div`
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #E7F3EB;
    color: #42A764;
    text-align: center;
    vertical-align: bottom;
    font-size: 2rem;
`;
export const CustomAlert = styled(Alert)`
background-color: #F3F5F9;
border:none;
h3{
font-size:15px;
font-weight:500;
color;#343747;
font-family: "Haus Neue";
}
@media  (max-width:768px) {
padding: 0.5rem !important;
}
`;
// export const Heading = styled.span`
//     font-size: 1.5rem;
//     font-weight: 600;
//     align-self: center;
//     color: #343747;
// `

export const Heading = styled.p`
    color: #4fb973;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0px;
`;

export const SubHeading = styled.p`
    color: #565656;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
`;

export const SectionTitle = styled.div`
    color: #3c3c3c;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid #d3d6dd;
`;

export const OtpContainer = styled.div`
display: flex;
justify-content: center;
margin-top: 3rem;
.otpInput {
    width: 50px !important;
    height: 50px !important;
    background: #fcfcfc;
    border-radius: 5px;
    padding: 10px;
    color: #4fb973;
    font-weight: 700;
    @media (max-width: 425px) {
        width: 30px !important;
        height: 30px !important;
    }
}
`;

export const FormLabel = styled.label`
color:  #161616;
text-align: left;
font-size: 12px;
font-weight: 400;
`;

export const ErrorContainer = styled(Form.Control.Feedback)`
    font-weight: 600 !important;
    margin-top: 0rem;
    font-size: 1rem !important;
    font-family: Hauora;
`;

export const FormInput = styled.input`
 display: block;
 padding: 7px 14px;
 font-size: 16px;
 border: 1px solid #cccccc;
 /* Add a border to the input */
 border-radius: 4px;
 box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
 transition: box-shadow 150ms ease;
 line-height: normal;
 width:100%;
`;

export const AccountRow = styled(Row)`
.w-8{
    width: 8rem;
}   
.eye-icon{
  top: 39px;
  width:14px;
  right: 17px; 
}
`;

export const SubHeader = styled.h5`
color: #343747;
font-family: "Haus Neue";
font-size: 16px;
font-weight: 600;
`
export const Font14 = styled.p`
    font-size: 14px;
    font-weight: 600;
    color:#3C3C3C;
  `
export const EmergencyHeader = styled.div`
   background-color: #F3F9F5;
   padding: 0.5rem;
   font-family: "Haus Neue";
   font-size:1rem;
   font-weight: 600;
`
export const HeaderLocation = styled.div`
 background-color: #F3F9F5;
   padding: 0.5rem;
   font-family: "Haus Neue";
   font-size:1.25rem;
   font-weight: 600;
`
export const TimeStamp = styled.div`
   background-color: #F3F9F5;
   padding: 0.5rem 0.8rem;
   font-family: "Haus Neue";
   font-size:0.75rem;
   font-weight: 400;
   border-radius:1rem  
`
export const TimeStampBgWhite = styled.div`
   background-color: #Ffffff;
   padding: 0.5rem 0.8rem;
   font-family: "Haus Neue";
   font-size:0.75rem;
   font-weight: 400;
   border-radius:1rem  
`
export const GrayContainer = styled.div`
background-color: #F3F5F9;
border-radius: 7px;
padding: 2rem;
margin: 0.3rem 0;
.role-heading{
  font-size: 22px;
  font-weight: 500;
  color:#343747;
}
.location-heading{
  font-size: 1rem;
  font-weight: 600;
  color:#343747;
}
@media  (max-width:768px) {
padding: 0.5rem;

.card-body{
  padding:0.5rem;
}
`
export const CardBody = styled(Card.Body)`
    .charcoal-blue{
        color: #343747;
    }
    :hover {
        background: #4FB973;
        transition: all 0.2s ease-out;

        .charcoal-blue {
            color: #fff !important;
        }

        .figure-caption {
            color: #fff !important;
        }
    }
    .figure-caption{
        h6{
            @media screen and (max-device-width:1024px) and (orientation: portrait) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            @media screen and (max-device-width:768px) and (orientation: landscape) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
         
        }
    }
    @media  (max-width:768px) {
      .responsive-table{
        overflow:auto;
      th {
        min-width: 11rem;
      }
    }
  }
`;
export const EquipmentRecords = styled.div`
@media  (max-width:768px) {
      .responsive-table{
        overflow:auto;
      th {
        min-width: 11rem;
      }
    }
}
`
export const IconContainer = styled(Figure)`
    margin: 0;
    display: flex !important;
`;

export const ImageContainer = styled(Figure.Image)`
    margin-right: 0.875rem;
    align-self: flex-start;
`;


export const SectionBlock = styled(Col)`
  border: 1px solid #bfb6b6 !important;
  border-radius:7px !important;
  padding: 2rem;

  tbody {
    tr:nth-child(odd) {
      background-color: #f9f9f9;
    }
  }
  @media  (max-width:768px) {
     padding: 0.5rem !important;

     .responsive-table{
     overflow:auto;
     th {
     min-width: 11rem;
     }
     }
   }
`;

export const MyAccountContainer = styled.div`
    min-height: auto;
    max-height: calc(100vh - 16rem);
    overflow:auto;
    padding: 1rem 2rem;
    @media  (max-width:768px) {
     padding: 0.5rem;
      height:auto
   }
`

export const SectionHeading = styled.p`
color: #343747;
font-family: "Haus Neue";
font-size: 16px;
font-weight: 600;
margin-bottom: 0px;
display: flex;
justify-content: space-between;
border-bottom: 1px solid #D3D6DD;
`;
export const NavigationContainer = styled.div`

`;

export const NavigationTabSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
    color:#676767 !important;
    margin-bottom: 1rem!important;
    margin-right: 0px !important; 
    margin-left: 0px !important;
    line-height: 30px;
    & > * {
    flex: 1 1 150px; // Minimum width of 200px, can grow and shrink
  }
`;


export const NavTab = styled.div`
    font-size: 1.2rem;
    font-weight: 600 !important;
    text-align: center;
    cursor:pointer;
    border-bottom: 1px solid #676767;
    @media screen and (max-device-width: 1024px) and (orientation: portrait) {
        line-height: 3rem;
        white-space: pre;
    }
    @media screen and (max-device-width:1024px) and (orientation: landscape) {
        line-height: 3rem;
        white-space: pre;
    }
    
    :hover{
        background: #e3ffec !important;
    }  
    &.active {
        color:#42A764 !important;
        border-bottom: 2px solid #42A764;
    }
`;

export const NavHeader = styled.h2`
    padding:0.875rem;
    margin:0;
    @media screen and (max-device-width:4000px) and (orientation: portrait) {
        margin-left: 1.5rem;
    }
    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin-left: 1.5rem;
    }
`;
export const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;

export const CustomPhoneInput = styled(PhoneInput)`
    width: 100%;
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    background: white;
    .PhoneInputCountry{
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        padding: 7px;
    }
    input{
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        padding: 9px;
        transition: border 0.15s linear, box-shadow 0.15s linear;
        width:100%;
        margin-top:2px;
        margin-bottom:2px;
    }
`;

export const NameBlock = styled.span`
    padding:8px 8px
    border-radius:50%;
    background-color:#E7F3EB;
    color:#4FB973;
    margin-right: 1rem;
    font-size: ${(props) => (props.fontSize)};
    text-transform: uppercase;
    height: ${(props) => (props.height)};
    width: ${(props) => (props.height)};
    text-align: center;
`;

export const VerifyContainer = styled.div`
position: absolute;
top: 5px;
right: 5px;
`

export const BillingContainer = styled.div`

`;

export const BillingProductContainer = styled(Col)`
    background-color: #F9F9F9;
    width: 100%
    padding: 30px;
    @media (max-width: 991px) {
        width: 100% !important;
    }
`;
export const PersonalInfoContainer = styled.div` 
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 10%);
    padding: 25px;
    margin-top: 5rem;
    width: 30%;

    @media (max-width: 1300px) {
        width: 90%;
    }
 `;

export const WhiteButton = styled(Button)`
  border-radius: 4px;
  text-decoration: none;
  background: #fbfbfb;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: 0.5px solid #eaeaea;
  color: #7f808f;
  transition: box-shadow 0.2s;
`;

export const ProductCard = styled(PersonalInfoContainer)`
    width:100%
    margin-top: 2rem;
    padding: 1rem;
    ${({ selected }) => selected && "border : 2px solid ##4fb973 !important;    box-shadow: 1px 1px 4px 1px #4fb973;"}
    
    @media (max-width: 991px) {
        width: 100% !important;
    }
`;

export const ProductCardTitleContainer = styled.div`
    border-bottom: 1px solid #d3d6dd;
`;

export const ProductCardTitle = styled.p`
    color: #343747;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
`;

export const ProductCardSubTitle = styled.p`
    color: #7e818f;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
`;

export const PaymentInfoContainer = styled(PersonalInfoContainer)`
    width: 100%
    margin-top: 0rem;
    padding: 30px;
    @media (max-width: 991px) {
        width: 100% !important;
    }
.StripeElement {
    display: block;
    padding: 13px;
    font-size: 16px;
    border: 1px solid #ccc;
    /* Add a border to the input */
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    border-color: #4fb973;
    /* Change border color when the input is focused */
    box-shadow: 0 0 0 2px rgba(79, 185, 115, 0.30);
}
`;

export const PriceSubTotal = styled.p`
    text-align: center;
    text-decoration: line-through;
    align-items: center;
    color: #656772;
    font-size: 18px;
    margin-bottom:0px;
`;

export const PriceTotal = styled(Col)`
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    color: #343747;
    font-size: 25px;
    font-weight: 600;
`;

export const Chip = styled.div`
    justify-content: space-between;
    display: flex;
    min-width: 50%;
    // max-width: 75%;
    font-family: 'Hauora';
    background: #98e7bf;
    border: 0.1px solid #b9bcca;
    border-radius:10px;
    padding: 5px 10px;
    box-shadow: inset 0 0.2px 0.2px 0 rgba(0, 0, 0, 0.16);
    span{
        font-size:16px;
        font-weight:600;
    };
    i{
        font-Size:18px;
        margin: auto 0px;
        color:#fd0f0f;
        cursor:pointer;
    }
`;

