import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlert, CustomCard, SubHeader, AccountRow, Font14, GrayContainer, TimeStampBgWhite, HeaderLocation, NavigationTabSection, NavTab, MyAccountContainer } from "../Account/Style";
import { Button, Col, Row, Modal, } from "react-bootstrap";
import pencilGreen from '../../assets/images/pencil-green.svg';
import AlertInfo from '../../assets/my-account/alert_info.svg';
import CreateNewPractice from "./CreateNewPractice";
import moment from 'moment';
import axios from "axios";
import { loadUser } from "../../actions";

const PracticeLocations = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.currentUser);
    const [activeTab, setActiveTab] = useState('Active');
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invitationData, setInvitationData] = useState([]);
    const [selectedInvite, setSelectedInvite] = useState(null);
    const [action, setAction] = useState('');
    const [isOpenCreateNewPracticeModal, setIsOpenCreateNewPracticeModal] = useState(false);

    useEffect(() => {
        getInvitations();
    }, []);

    const selectTab = (tab) => {
        setActiveTab(tab);
    };
    const handleClose = () => setShow(false);

    const handleShow = (selectedAction, invite) => {
        setAction(selectedAction);
        setSelectedInvite(invite);
        setShow(true);
    };

    const updateInvitation = async (action) => {
        try {
            const updatedInvite = { action, ...selectedInvite };
            setLoading(true);
            const { data: { payload } } = await axios.put(`/api/v2/updateInvite/${user._id}`, updatedInvite);
            getInvitations();
            setShow(false);
            setAction('');
            setSelectedInvite(null);
            dispatch(loadUser(user));
        } catch (error) {
            setLoading(false);
        }
    };

    const openCreateNewPracticeModal = () => {
        setIsOpenCreateNewPracticeModal(true);
    };
    const getInvitations = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/getUserInvitations/${user._id}`);
            setInvitationData(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    return (
        <MyAccountContainer>
            <Row className="m-0 justify-content-end">
                <Col lg={2} md={6} sm={12} className="p-0 px-1">
                    <Button variant='success' className="px-5  w-100 my-1" onClick={() => openCreateNewPracticeModal()}>Create New Practice</Button>
                </Col>
            </Row>
            <CustomCard className='mt-4'>
                <CustomCard.Body>
                    <SubHeader className='font-weight-bold mb-0'>Pending Invitations</SubHeader>
                    <Font14 className="fw-normal mt-1 letter-spacing-1">New Practices inviting you to join their Ocoord account. Accepting means your Contact Information, Health Documents, and Education & Training Records will be visible to administrators at these locations. </Font14>
                    <hr className="my-2" />
                    {(invitationData && invitationData.length > 0) && invitationData.map((invite) => (
                        <AccountRow className='mt-3'>
                            <Col xs={12}>
                                <HeaderLocation className="mb-2">{invite.practiceDetails.name}</HeaderLocation>
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <GrayContainer>
                                    <div className="d-flex">
                                        <span className="align-self-center">
                                            <p className="mb-1 location-heading">{invite.locationDetails.name}</p>
                                            <p className="letter-spacing-1">{invite.locationDetails.address.address + ', ' + invite.locationDetails.address.city + ', ' + invite.locationDetails.address.state + ', ' + invite.locationDetails.address.zip}</p>
                                        </span>
                                    </div>
                                    <div className="d-flex">
                                        <span className='font-weight-bold d-inline-block align-self-center'>
                                            Invited:
                                        </span>
                                        <TimeStampBgWhite className="rounded-3 ml-2">{moment(invite.invitedAt).format('MM-DD-YYYY')}</TimeStampBgWhite>
                                    </div>
                                    <hr />
                                    <Row className="m-0">
                                        <Col lg={6} md={6} sm={12} className="p-0 px-1">
                                            <Button variant='light' className="px-5 text-success w-100 bg-white my-1" onClick={() => handleShow('declined', invite)}>Decline Invitation </Button>
                                        </Col>
                                        <Col lg={6} md={6} sm={12} className="p-0 px-1">
                                            <Button variant='success' className="px-5  w-100 my-1" onClick={() => handleShow('accepted', invite)}>Accept Invitation</Button>
                                        </Col>
                                    </Row>
                                </GrayContainer>
                            </Col>
                        </AccountRow>
                    ))}
                    {(invitationData && invitationData.length === 0) &&
                        <CustomAlert id='error-alert' className='my-2 py-2 font info w-100 d-flex' style={{ 'maxWidth': '100%' }} variant="secondary">
                            <img src={AlertInfo} alt='AlertInfo' />
                            <h3 className='w-100 align-self-center  mb-0 ml-2 letter-spacing-1'>
                                No pending invitations.
                            </h3>
                        </CustomAlert>
                    }
                </CustomCard.Body>
            </CustomCard>

            <CustomCard className='mt-5'>
                <CustomCard.Body>
                    <div className="d-flex justify-content-between">
                        <div>
                            <SubHeader className='font-weight-bold mb-0'>Primary Location</SubHeader>
                            <Font14 className="fw-normal mt-1 letter-spacing-1">The location you work at most. Your annual compliance requirements are based on this location’s Annual Review Date.</Font14>
                        </div>
                        <Button type='button' variant='' className='text-success px-4'>
                            <img className='cursor-pointer mr-2' src={pencilGreen} alt="Edit Arrow" />
                            <strong>{'Edit'}</strong>
                        </Button>
                    </div>
                    <hr className="my-2" />
                    <AccountRow className='mt-3'>
                        <Col xs={12}>
                            <HeaderLocation className="mb-2">Charlotte Dental</HeaderLocation>
                        </Col>
                        <Col lg={6} md={12} sm={12} >
                            <GrayContainer>
                                <div className="d-flex">
                                    <span className="align-self-center">
                                        <p className="mb-1 location-heading">Myers Park</p>
                                        <p className="letter-spacing-1 mb-0">1300 East Blvd. Unit 700, Charlotte, NC 28203</p>
                                    </span>
                                </div>
                                <hr className="my-2" />
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="d-flex">
                                        <span className='font-weight-bold d-inline-block align-self-center'>
                                            Invited:
                                        </span>
                                        <TimeStampBgWhite className="rounded-3 ml-2">10/05/2024</TimeStampBgWhite>
                                    </div>
                                    <div className="d-flex">
                                        <span className='font-weight-bold d-inline-block align-self-center'>
                                            Next Annual Review (Training Due):
                                        </span>
                                        <TimeStampBgWhite className="rounded-3 ml-2">10/05/2024</TimeStampBgWhite>
                                    </div>
                                </div>
                            </GrayContainer>
                        </Col>
                    </AccountRow>
                </CustomCard.Body>
            </CustomCard>

            <CustomCard className='mt-5'>
                <CustomCard.Body>
                    <SubHeader className='font-weight-bold mb-0'>Additional Practice Locations</SubHeader>
                    <hr className="my-2" />
                    <NavigationTabSection className="mx-0 px-3 w-100">
                        <NavTab lg='4' sm='4' md='4' title="All" onClick={() => selectTab('Active')} className={activeTab === 'Active' ? 'active' : ''}>
                            Active
                        </NavTab>
                        <NavTab onClick={() => selectTab('Archived')} className={activeTab === 'Archived' ? 'active' : ''} lg='4' sm='4' md='4' title="Archived" >
                            Archived
                        </NavTab>
                    </NavigationTabSection>
                    {activeTab === 'Active' ?
                        <div>
                            <CustomAlert id='error-alert' className='my-2 py-2 font info w-100 d-flex' style={{ 'maxWidth': '100%' }} variant="secondary">
                                <img src={AlertInfo} alt='AlertInfo' />
                                <h3 className='w-100 align-self-center  mb-0 ml-2 letter-spacing-1'>
                                    Locations you actively perform job duties whether regularly or irregularly.
                                </h3>
                            </CustomAlert>
                            <AccountRow className='mt-3'>
                                <Col xs={12}>
                                    <HeaderLocation className="mb-2">Greeneville Dental</HeaderLocation>
                                </Col>
                                <Col lg={6} md={12} sm={12}>
                                    <GrayContainer>
                                        <div className="d-flex">
                                            {/* <img src={Help} alt='AlertInfo' /> */}
                                            <span className="align-self-center">
                                                <p className="mb-1 location-heading">Clemson Blvd</p>
                                                <p className="letter-spacing-1 mb-0">1300 East Blvd. Unit 700, Charlotte, NC 28203</p>
                                            </span>
                                        </div>
                                        <hr className="my-2" />
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="d-flex">
                                                <span className='font-weight-bold d-inline-block align-self-center'>
                                                    Joined:
                                                </span>
                                                <TimeStampBgWhite className="rounded-3 ml-2">10/05/2024</TimeStampBgWhite>
                                            </div>
                                            <div className="d-flex">
                                                <span className='font-weight-bold d-inline-block align-self-center'>
                                                    Departed:
                                                </span>
                                                <TimeStampBgWhite className="rounded-3 ml-2">10/05/2024</TimeStampBgWhite>
                                            </div>
                                        </div>
                                    </GrayContainer>
                                </Col>
                                <Col lg={6} md={12} sm={12}>
                                    <GrayContainer>
                                        <div className="d-flex">
                                            {/* <img src={Help} alt='AlertInfo' /> */}
                                            <span className="align-self-center">
                                                <p className="mb-1 location-heading">Downtown</p>
                                                <p className="letter-spacing-1 mb-0">1300 East Blvd. Unit 700, Charlotte, NC 28203</p>
                                            </span>
                                        </div>
                                        <hr className="my-2" />
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="d-flex">
                                                <span className='font-weight-bold d-inline-block align-self-center'>
                                                    Joined:
                                                </span>
                                                <TimeStampBgWhite className="rounded-3 ml-2">10/05/2024</TimeStampBgWhite>
                                            </div>
                                            <div className="d-flex">
                                                <span className='font-weight-bold d-inline-block align-self-center'>
                                                    Departed:
                                                </span>
                                                <TimeStampBgWhite className="rounded-3 ml-2">10/05/2024</TimeStampBgWhite>
                                            </div>
                                        </div>
                                    </GrayContainer>
                                </Col>
                                <Col xs={12}>
                                    <HeaderLocation className="my-2">Smith Dentistry</HeaderLocation>
                                </Col>
                                <Col lg={6} md={12} sm={12}>
                                    <GrayContainer>
                                        <div className="d-flex">
                                            {/* <img src={Help} alt='AlertInfo' /> */}
                                            <span className="align-self-center">
                                                <p className="mb-1 location-heading">Joe Smith</p>
                                                <p className="letter-spacing-1 mb-0">1300 East Blvd. Unit 700, Charlotte, NC 28203</p>
                                            </span>
                                        </div>
                                        <hr className="my-2" />
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="d-flex">
                                                <span className='font-weight-bold d-inline-block align-self-center'>
                                                    Joined:
                                                </span>
                                                <TimeStampBgWhite className="rounded-3 ml-2">10/05/2024</TimeStampBgWhite>
                                            </div>
                                        </div>
                                    </GrayContainer>
                                </Col>
                            </AccountRow>
                        </div>
                        : null}
                </CustomCard.Body>
            </CustomCard>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center" >
                    <div>
                        <img src={AlertInfo} alt='AlertInfo' />
                    </div>
                    Are you sure you want to <b>{action}</b> this invitation?
                </Modal.Body>
                <Modal.Footer className="border-0 d-block">
                    <Row>
                        <Col>
                            <Button className="w-100" variant="light" onClick={() => setShow(false)} disabled={loading}>
                                No
                            </Button>
                        </Col>
                        <Col>
                            <Button className="w-100" variant="success" onClick={() => updateInvitation(action)} disabled={loading}>
                                {loading ? 'Loading...' : 'Yes'}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            {
                isOpenCreateNewPracticeModal &&
                <CreateNewPractice onClose={setIsOpenCreateNewPracticeModal}></CreateNewPractice>
            }
        </MyAccountContainer>
    );
};

export default PracticeLocations;