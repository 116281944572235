import { useSelector } from "react-redux";
import { CustomAlert, CustomCard, SubHeader, AccountRow, Font14, GrayContainer, TimeStampBgWhite, MyAccountContainer } from "../Account/Style";
import AlertInfo from '../../assets/my-account/alert_info.svg';
import Help from '../../assets/my-account/help.svg';
import Select from 'react-select';
import Clinic from '../../assets/my-account/clinic_bg.svg';
import PersonalProtectiveEquipment from './PersonalProtectiveEquipment'
import pencilGreen from '../../assets/images/pencil-green.svg';
import { Button, Col } from "react-bootstrap";


import { apiUrl, DropDownStyle } from '../../helpers'

const RolesAndPPE = () => {
    const user = useSelector((state) => state.user.currentUser);

    return (
        <MyAccountContainer>
            <CustomAlert id='error-alert' className='my-2 py-2 font info w-100 d-flex' style={{ 'maxWidth': '100%' }} variant="secondary">
                <img src={AlertInfo} alt='AlertInfo' />
                <h3 className='w-100 align-self-center  mb-0 ml-2'>View and manage training completions and additional educational certificates.</h3>
            </CustomAlert>
            <div className="mt-4">
                <span className='font-weight-bold d-inline-block'>
                    Location:
                </span>
                <span className='font-weight-bold d-inline-block '>
                    <Select
                        styles={DropDownStyle}
                    // defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                    // value={pageSizeOptions.filter((val) => val.value === pageSize)}
                    // options={pageSizeOptions}
                    // onChange={(e) => this.handlePageSizeChange(e.value)}
                    />
                </span>
            </div>
            <CustomCard className='mt-4'>
                <CustomCard.Body>
                    <div className="d-flex justify-content-between">
                        <SubHeader className='font-weight-bold mb-0'>Primary Role & Exposure Risk</SubHeader>
                    </div>
                    <hr className="my-2" />
                    <CustomCard.Text>
                        <AccountRow className='mt-3'>
                            <Col lg={6} md={12} sm={12}>
                                <span className='font-weight-bold d-inline-block w-8'>
                                    Primary Role:
                                </span>
                                <span>Clinical</span>
                                <hr />
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <span className='font-weight-bold d-inline-block w-8'>
                                    Exposure Risk:
                                </span>
                                <span>At Risk</span>
                                <hr />
                            </Col>
                            <Col xs={12} className='col-12'>
                                <Font14 className="mb-0 mt-2">Additional Roles</Font14>
                                <hr className="mt-1" />
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <GrayContainer>
                                    <div className="d-flex flex-column flex-lg-row flex-md-row">
                                        <img src={Help} alt='AlertInfo' width={84} />
                                        <span className="align-self-center ml-3">
                                            <p className="mb-1 role-heading">OSHA Coordinator</p>
                                            <p className="letter-spacing-1">Responsible for managing the overall OSHA compliance program at this office location.</p>
                                        </span>
                                    </div>
                                    <hr />
                                    <div className="d-flex mt-3">
                                        <span className='font-weight-bold d-inline-block w-8 align-self-center'>
                                            Last updated:
                                        </span>
                                        <TimeStampBgWhite className="rounded-3">10/05/2024</TimeStampBgWhite>
                                    </div>
                                </GrayContainer>
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <GrayContainer>
                                    <div className="d-flex flex-column flex-lg-row flex-md-row">
                                        <img src={Clinic} alt='AlertInfo' width={84} />
                                        <span className="align-self-center ml-3">
                                            <p className="mb-1 role-heading">SPICE Officer</p>
                                            <p className="letter-spacing-1">
                                                Responsible for ensuring compliance with the specific NC Dental Infection Control rules and regulations.
                                            </p>
                                        </span>
                                    </div>
                                    <hr />
                                    <div className="d-flex mt-3">
                                        <span className='font-weight-bold d-inline-block w-8 align-self-center'>
                                            Last updated:
                                        </span>
                                        <TimeStampBgWhite className="rounded-3">10/05/2024</TimeStampBgWhite>
                                    </div>
                                </GrayContainer>
                            </Col>
                        </AccountRow>
                    </CustomCard.Text>
                </CustomCard.Body>
            </CustomCard>

            <CustomCard className='mt-5'>
                <CustomCard.Body>
                    <div className="d-flex justify-content-between">
                        <SubHeader className='font-weight-bold mb-0'>Personal Protective Equipment</SubHeader>
                        <Button type='button' variant='' className='text-success px-4'>
                            <img className='cursor-pointer mr-2' src={pencilGreen} alt="Edit Arrow" />
                            <strong>{'Edit'}</strong>
                        </Button>
                    </div>
                    <hr className="my-2" />
                    <CustomCard.Text>
                        <PersonalProtectiveEquipment></PersonalProtectiveEquipment>
                    </CustomCard.Text>
                </CustomCard.Body>
            </CustomCard>

        </MyAccountContainer>
    );
};

export default RolesAndPPE;