import { useSelector } from "react-redux";
import { CustomCard, SubHeader, AccountRow, Font14, MyAccountContainer } from "../Account/Style";
import { Button, Col, Row, } from "react-bootstrap";
import { Checkbox } from '../../components/Checkbox'
import Info from '../../assets/images/info.svg';

const NotificationPreferences = () => {
    const user = useSelector((state) => state.user.currentUser);

    return (
        <MyAccountContainer>
            <CustomCard className='mt-3'>
                <CustomCard.Body>
                    <SubHeader className='font-weight-bold mb-0'>Notifications</SubHeader>
                    <Font14 className="fw-normal mt-1 letter-spacing-1">Select when and how you’ll be notified.</Font14>
                    <hr className="my-2" />
                    <AccountRow className='mt-3'>
                        <Col xs lg={6} md={12} sm={12} className="p-0">
                            <Col xs={12}>
                                <div className="d-flex justify-content-between">
                                    <Font14 className="mb-0 mt-2">Additional Roles</Font14>
                                    <Button variant="" className="bg-white text-secondary">
                                        <img src={Info} alt='Info' width={15} className="mr-2" />
                                        Clear All
                                    </Button>
                                </div>
                                <hr />
                            </Col>
                            <Row className="m-0 py-2">
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <label htmlFor="">I`m assigned to a task</label>
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    In-app
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Email
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Text
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    None
                                </Col>
                            </Row>
                            <Row className="m-0 py-2">
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <label htmlFor="">A task is overdue</label>
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    In-app
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Email
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Text
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    None
                                </Col>
                            </Row>
                            <Row className="m-0 py-2">
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <label htmlFor="">A task status is updated</label>
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    In-app
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Email
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Text
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    None
                                </Col>
                            </Row>
                            <Row className="m-0 py-2">
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <label htmlFor="">I`m mentioned in a message</label>
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    In-app
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Email
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Text
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    None
                                </Col>
                            </Row>
                            <Col xs={12}>
                                <Font14 className="mb-0 mt-2">Updates</Font14>
                                <hr />
                            </Col>
                            <Row className="m-0 py-2">
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <label htmlFor="">Occord updates</label>
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    In-app
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Email
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Text
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    None
                                </Col>
                            </Row>
                            <Row className="m-0 py-2">
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <label htmlFor="">Library updates</label>
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    In-app
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Email
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Text
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    None
                                </Col>
                            </Row>
                            <Row className="m-0 py-2">
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <label htmlFor="">Safety data sheets updates</label>
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    In-app
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Email
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Text
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    None
                                </Col>
                            </Row>
                            <Row className="m-0 py-2">
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <label htmlFor="">Library updates</label>
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    In-app
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Email
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Text
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    None
                                </Col>
                            </Row>
                            <Row className="m-0 py-2">
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <label htmlFor="">Form library updates</label>
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    In-app
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Email
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    Text
                                </Col>
                                <Col xs={3} lg={2} md={3} sm={3}>
                                    <Checkbox name='test' disabled={false} />
                                    None
                                </Col>
                            </Row>
                        </Col>
                    </AccountRow>
                </CustomCard.Body>
            </CustomCard>
            <div className="col-12 d-flex justify-content-end mt-2">
                <Button variant='light' className="px-5 mx-2 text-success">Cancel</Button>
                <Button variant='success' className="px-5 ml-2">Save</Button>
            </div>
        </MyAccountContainer>
    );
};

export default NotificationPreferences;