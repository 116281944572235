import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Router, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { AuthRoute } from '../../components/AuthRoute';
import { NavHeader, NavTab, NavigationContainer, NavigationTabSection } from './Style';
import BasicDetails from './BasicDetails';
import HealthDocuments from './HealthDocuments';
import EducationAndTraining from './EducationAndTraining';
import RolesAndPPE from './RolesAndPPE';
import TasksAndRecords from './TasksAndRecords';
import PracticeLocations from './PracticeLocations';
import NotificationPreferences from './NotificationPreferences';

const AdminContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 15px;
    z-index:0;
    justify-content: start;
    margin: 0 0rem 0 4rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border-radius:10px;
    @media screen and (max-device-width: 4000px) and (orientation: portrait) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width:85%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin: 6.5rem 0.5rem 0rem 6rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        margin:6.5rem 0.5rem 0rem 6rem !important;
        max-width:100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    .min-w-15{
        min-width:15% !important;
    }
    .min-w-25{
        min-width:25% !important;
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
`;
class Admin extends PureComponent {
    componentDidMount() {
        const { user, history } = this.props;
        // if (!user.isAdmin) history.push('/');
    };

    componentWillReceiveProps = (updatedProps) => {
        // if (!updatedProps.user.isAdmin) updatedProps.history.push('/');
    };

    navigate = (route) => {
        const { history } = this.props;
        history.push(route);
    };

    render() {
        const { location: { pathname }, history, user, authenticated } = this.props;
        return (
            <AdminContainer className=' pb-2'>
                <NavigationContainer className='px-3'>
                    <NavHeader>My Account</NavHeader>
                    <NavigationTabSection >
                        <NavTab onClick={() => this.navigate('/account')} title="Staff Members" className={['/account'].includes(pathname) ? 'active' : ''}>
                            Basic Details
                        </NavTab>
                        <NavTab className={['/account/healthDocuments'].includes(pathname) ? 'active' : ''} title="Roles" onClick={() => this.navigate('/account/healthDocuments')}>
                            Health Documents
                        </NavTab>
                        <NavTab className={['/account/educationAndTraining'].includes(pathname) ? 'active' : ''} title="Safety Equipments" onClick={() => this.navigate('/account/educationAndTraining')}>
                            Education & Training
                        </NavTab>
                        <NavTab className={['/account/rolesAndPpe'].includes(pathname) ? 'active' : ''} title="Task" onClick={() => this.navigate('/account/rolesAndPpe')}>
                            Roles & PPE
                        </NavTab>
                        <NavTab className={['/account/tasksAndRecords'].includes(pathname) ? 'active' : ''} title="Practice Locations" onClick={() => this.navigate('/account/tasksAndRecords')}>
                            Tasks & Records
                        </NavTab>
                        <NavTab className={['/account/practiceLocations'].includes(pathname) ? 'active' : ''} title="Subscription & Billing" onClick={() => this.navigate('/account/practiceLocations')}>
                            Practice Locations
                        </NavTab>
                        <NavTab className={['/account/notificationAndPreferences'].includes(pathname) ? 'active' : ''} title="Subscription & Billing" onClick={() => this.navigate('/account/notificationAndPreferences')}>
                            Notification Preferences
                        </NavTab>
                    </NavigationTabSection>
                </NavigationContainer>
                <Router history={history}>
                    <Switch>
                        <AuthRoute
                            exact
                            authenticated={authenticated}
                            user={user}
                            component={BasicDetails}
                            path='/account'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={HealthDocuments}
                            path='/account/healthDocuments'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={EducationAndTraining}
                            path='/account/educationAndTraining'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={RolesAndPPE}
                            path='/account/rolesAndPpe'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={TasksAndRecords}
                            path='/account/tasksAndRecords'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={PracticeLocations}
                            path='/account/practiceLocations'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={NotificationPreferences}
                            path='/account/notificationAndPreferences'
                        />

                    </Switch>
                </Router>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    authenticated: state.user.authenticated,
});

export default withRouter(connect(mapStateToProps)(Admin));