import { useDispatch, useSelector } from "react-redux";
import { CustomAlert, CustomCard, Heading, AccountRow, SubHeader, Font14, EmergencyHeader, TimeStamp, MyAccountContainer, StyledLabel, CustomPhoneInput, NameBlock, SubHeading, OtpContainer, FormLabel, FormInput, VerifyContainer } from "../Account/Style";
import AlertInfo from '../../assets/my-account/alert_info.svg';
import pencilGreen from '../../assets/images/pencil-green.svg';
import User from '../../assets/images/user.svg';
import Phone from '../../assets/my-account/phone.svg';
import Email from '../../assets/my-account/mail.svg';
import Clinic from '../../assets/images/clinic.svg';
import Allergies from '../../assets//my-account/allergies.svg';
import infoIcon from '../../assets/images/info.svg';
import Religious from '../../assets/my-account/religious.svg';
import refreshIcon from '../../assets/images/green_refresh.svg';
import { Input } from "../../components/Input";
import { Alert, Button, Col, Modal } from "react-bootstrap";
import OtpInput from 'react-otp-input';
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import eyeIcon from "../../assets/images/eye.svg";
import eyeOffIcon from "../../assets/images/eyeOff.svg";
import { getCountryCallingCode, isValidPhoneNumber } from "react-phone-number-input";
import { changePasswordInside, loadUser } from "../../actions";
import { StatusBadge, TransparentButton } from "../../components";

const BasicDetails = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.currentUser);
    const [userDetails, setUserDetails] = useState({ firstName: user.firstName, lastName: user.lastName, email: user.email, phone: user.phone });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ invalid: false, password: false, firstName: false, lastName: false, email: false, phone: false, apiError: false, errorMessage: '' });
    const [countryCode, setCountryCode] = useState(user.countryCode);
    const [isEditContactInformation, setIsEditContactInformation] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [passwordDetails, setPasswordDetails] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
    const [showPassword, setShowPassword] = useState({ currentPassword: false, newPassword: false, confirmPassword: false });
    const [emergencyContactInformation, setEmergencyContactInformation] = useState({
        mainContact: user.mainEmergencyContact || {},
        physicianContact: user.physicianEmergencyContact || {},
        hospitalPreference: user.hospitalPreferenceEmergencyContact || {},
        pharmacyContact: user.pharmacyEmergencyContact || {},
        outOfTownContact: user.outOfTownEmergencyContact || {},
        allergies: user.allergies || [{ name: '' }],
        religiousOrganization: user.religiousOrganization || { name: '' }
    });
    const [inputValue, setInputValue] = useState(
        user.allergies && user.allergies.length > 0
            ? user.allergies.map(a => a.name).join(", ")
            : ""
    );
    const [isEditMainEmergencyContact, setIsEditMainEmergencyContact] = useState(false);
    const [isEditAdditionalInformation, setIsEditAdditionalInformation] = useState(false);
    const [isEmailChanged, setIsEmailChanged] = useState(false);
    const [isPhoneChanged, setIsPhoneChanged] = useState(false);
    const [isOpenVerifyOtpModal, setIsOpenVerifyOtpModal] = useState(false);
    const [otp, setOtp] = useState('');

    useEffect(() => {
        const hasChanged = Object.keys(userDetails).some((key) => userDetails[key] !== user[key]);
        setIsSaveEnabled(hasChanged);
    }, [userDetails, user]);

    const togglePasswordVisibility = (field) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    const handleLoadUser = () => {
        dispatch(loadUser(user));
    };

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleClose = () => {
        setIsOpenVerifyOtpModal(false);
    };

    const handleBlurOrEnter = () => {
        if (inputValue.trim()) {
            const updatedAllergies = inputValue
                .split(',')
                .map((allergy) => allergy.trim())
                .filter((allergy) => allergy) // Remove empty values
                .map((allergy) => ({ name: allergy }));

            setEmergencyContactInformation((prev) => ({
                ...prev,
                allergies: updatedAllergies
            }));
        }
    };

    const handleChangePasswords = async () => {
        try {
            await dispatch(changePasswordInside({
                password: passwordDetails.newPassword,
                currentPassword: passwordDetails.currentPassword,
                email: user.email
            }));
        } catch (error) {
            throw error;
        }
    };

    const sendCode = async () => {
        if (!validate()) {
            setLoading(true);
            if (isEmailChanged) {
                await checkEmailPresent(userDetails.email);
            };
            if (isPhoneChanged) {
                await checkPhonePresent(userDetails.phone);
            };
            setLoading(false);
        }
    };

    const checkEmailPresent = async (email) => {
        try {
            await axios.post('/api/v2/checkEmailId', { email, _id: user?._id });
            setIsOpenVerifyOtpModal(true);
            await sendOptOnEmail();
        } catch (error) {
            setErrors({ email: true, errorMessage: 'User email id already present.' });
            return;
        }
    };

    const checkPhonePresent = async (phone) => {
        try {
            await axios.post('/api/v2/checkPhoneId', { phone: phone, _id: user._id, countryCode });
            setIsOpenVerifyOtpModal(true);
            await sendOptOnPhone();
        } catch (error) {
            setErrors({ phone: true, errorMessage: 'User phone already present.' });
            return;
        }
    };

    const sendOptOnEmail = async () => {
        try {
            const { email, firstName } = userDetails;
            return await axios.post('/api/v2/sendOtpOnEmail', { email, firstName });
        } catch (error) {
            setLoading(false);
            return;
        }
    };

    const sendOptOnPhone = async () => {
        try {
            const { phone, firstName } = userDetails;
            return await axios.post('/api/v2/sendOtpOnPhone', { phone, firstName });
        } catch (error) {
            setLoading(false);
            return;
        }
    };

    const verifyOtp = async () => {
        try {
            setLoading(true);
            if (isEmailChanged) await axios.post('/api/v2/verifyOtp', { method: 'email', email: userDetails.email, otp: otp });
            if (isPhoneChanged) await axios.post('/api/v2/verifyOtp', { method: 'phone', phone: userDetails.phone, otp: otp });
            setIsOpenVerifyOtpModal(false);
            setIsEmailChanged(false);
            setIsPhoneChanged(false);
            setOtp('')
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'OTP verified successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            });
        } catch (error) {
            setLoading(false);
            if (error.response && error.response.status !== 500) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data,
                    allowEnterKey: true,
                    allowEscapeKey: false,
                    confirmButtonColor: '#4FB973',
                    confirmButtonText: 'Close',
                    allowOutsideClick: false
                });
            };
        }
    };

    const onEmailChange = (name, value) => {
        setUserDetails((prev) => {
            const updatedDetails = { ...prev, [name]: value };
            return updatedDetails;
        });
        if (name === 'email' && user.email !== value) {
            setIsEmailChanged(true);
        } else {
            setIsEmailChanged(false);
        }
        setErrors({ email: false, errorMessage: '' });
    };

    const onPhoneChange = (name, value) => {
        setUserDetails((prev) => {
            const updatedDetails = { ...prev, [name]: value };
            return updatedDetails;
        });
        if (name === 'phone' && user.phone !== value) {
            setIsPhoneChanged(true);
        } else {
            setIsPhoneChanged(false);
        }
        setErrors({ phone: false, errorMessage: '' });
    };

    const onModelChange = async (section, name, value) => {
        if (section === 'contactInformation') {
            setUserDetails((prev) => {
                const updatedDetails = { ...prev, [name]: value };
                return updatedDetails;
            });
        }

        if (section === 'passwordDetails') {
            setPasswordDetails((prev) => ({
                ...prev,
                [name]: value
            }));
        }

        if (section === 'emergencyContact' || section === 'additionalInformation') {
            setEmergencyContactInformation((prev) => ({
                ...prev,
                [name]: {
                    ...prev[name],
                    ...value
                }
            }));
        }
    };

    const countryChange = (e) => {
        setCountryCode(getCountryCallingCode(e));
    };

    const NameBlockElement = ({ firstName, lastName, height, fontSize }) => {
        firstName = firstName ? firstName : ' ';
        lastName = lastName ? lastName : ' ';
        height = height ? height : '2.5rem';
        fontSize = fontSize ? fontSize : '0.875em';
        return (
            <NameBlock fontSize={fontSize} height={height}>
                {firstName[0] + lastName[0]}
            </NameBlock>
        );
    };

    const validate = () => {
        const { firstName, lastName, email, phone } = userDetails;
        if (!firstName) {
            setErrors({ firstName: true, errorMessage: 'First Name must be needed.' });
            return true;
        } else if (!lastName) {
            setErrors({ lastName: true, errorMessage: 'Last Name must be needed.' });
            return true;
        } else if (!email) {
            setErrors({ email: true, errorMessage: 'Email must be needed.' });
            return true;
        } else if (!phone) {
            setErrors({ phone: true, errorMessage: 'Phone must be needed.' });
            return true;
        } else if (email && !email.match(/[^@]+@[^@]+\.[a-zA-Z]{2,6}/)) {
            setErrors({ email: true, errorMessage: 'Invalid Email address.' });
            return true;
        } else if (phone && phone.length > 3 && !isValidPhoneNumber(phone)) {
            setErrors({ phone: true, errorMessage: 'Invalid Phone number.' });
            return true;
        };
        return false;
    };

    const validateEmergencyContact = () => {
        const { mainContact, outOfTownContact, physicianContact, pharmacyContact } = emergencyContactInformation;
        if (mainContact && mainContact.phone && mainContact.phone.length > 3 && !isValidPhoneNumber(mainContact.phone)) {
            setErrors({ mainContact: true, errorMessage: 'Invalid Main Emergency Phone number.' });
            return true;
        } else if (outOfTownContact && outOfTownContact.phone && outOfTownContact.phone.length > 3 && !isValidPhoneNumber(outOfTownContact.phone)) {
            setErrors({ outOfTownContact: true, errorMessage: 'Invalid Out of town Phone number.' });
            return true;
        } else if (physicianContact && physicianContact.phone && physicianContact.phone.length > 3 && !isValidPhoneNumber(physicianContact.phone)) {
            setErrors({ outOfTownContact: true, errorMessage: 'Invalid Physician Phone number.' });
            return true;
        } else if (pharmacyContact && pharmacyContact.phone && pharmacyContact.phone.length > 3 && !isValidPhoneNumber(pharmacyContact.phone)) {
            setErrors({ outOfTownContact: true, errorMessage: 'Invalid Pharmacy Phone number.' });
            return true;
        }
        return false;
    };

    const cancel = async (section) => {
        if (section === 'contactInformation') {
            setUserDetails({ email: user.email, phone: user.phone, firstName: user.firstName, lastName: user.lastName });
            setErrors({ invalid: false, firstName: false, lastName: false, email: false, phone: false, apiError: false, errorMessage: '' })
            setIsEditContactInformation(false);
            setIsPhoneChanged(false);
            setIsEmailChanged(false);
        }
        if (section === 'passwordDetails') {
            setPasswordDetails({ currentPassword: '', newPassword: '', confirmPassword: '' });
            setErrors({ password: false, errorMessage: '' });
        }
        if (section === 'emergencyContact') {
            setEmergencyContactInformation({
                mainContact: user.mainEmergencyContact || {},
                physicianContact: user.physicianEmergencyContact || {},
                hospitalPreference: user.hospitalPreferenceEmergencyContact || {},
                pharmacyContact: user.pharmacyEmergencyContact || {},
                outOfTownContact: user.outOfTownEmergencyContact || {},
                allergies: user.allergies,
                religiousOrganization: user.religiousOrganization || {}
            });
            setIsEditMainEmergencyContact(false);
        }
        if (section === 'additionalInformation') {
            setEmergencyContactInformation({
                mainContact: user.mainEmergencyContact || {},
                physicianContact: user.physicianEmergencyContact || {},
                hospitalPreference: user.hospitalPreferenceEmergencyContact || {},
                pharmacyContact: user.pharmacyEmergencyContact || {},
                outOfTownContact: user.outOfTownEmergencyContact || {},
                allergies: user.allergies,
                religiousOrganization: user.religiousOrganization || {}
            });
            setIsEditAdditionalInformation(false);
        }
    };

    const save = (section) => {
        if (section === 'contactInformation') {
            saveContactInformation();
        }
        if (section === 'passwordDetails') {
            savePasswordDetails();
        }
        if (section === 'emergencyContact' || section === 'additionalInformation') {
            if (!validateEmergencyContact()) {
                const { mainContact, outOfTownContact, physicianContact, pharmacyContact, hospitalPreference, allergies, religiousOrganization } = emergencyContactInformation;
                const dataToUpdate = {
                    mainEmergencyContact: {
                        name: mainContact.name || '',
                        phone: mainContact.phone || '',
                        relation: mainContact.relation || ''
                    },
                    outOfTownEmergencyContact: {
                        name: outOfTownContact.name || '',
                        phone: outOfTownContact.phone || ''
                    },
                    physicianEmergencyContact: {
                        company: physicianContact.company || '',
                        name: physicianContact.name || '',
                        phone: physicianContact.phone || '',
                        address: physicianContact.address || ''
                    },
                    pharmacyEmergencyContact: {
                        name: pharmacyContact.name || '',
                        phone: pharmacyContact.phone || '',
                        address: pharmacyContact.address || ''
                    },
                    hospitalPreferenceEmergencyContact: {
                        name: hospitalPreference?.name || ''
                    },
                    allergies: allergies,
                    religiousOrganization: { name: religiousOrganization.name || '' }
                }
                saveEmergencyContact(dataToUpdate, section);
            }
        }
    };

    const saveContactInformation = async () => {
        if (!validate()) {
            try {
                setLoading(true);
                const dataToUpdate = { ...userDetails, countryCode };

                // Check if user is changing both email and phone
                const isEmailChanged = userDetails.email !== user.email;
                const isPhoneChanged = userDetails.phone !== user.phone;

                if (isEmailChanged && isPhoneChanged) {
                    setErrors({
                        invalid: true,
                        errorMessage: 'You can update either email or phone number, not both at the same time.'
                    });
                    setLoading(false);
                    return;
                }
                await axios.put('/api/v2/user/' + user._id, dataToUpdate);
                setIsEditContactInformation(false);
                handleLoadUser();
                Swal.fire({
                    icon: 'success',
                    title: 'User has been updated successfully',
                    allowEnterKey: true,
                    allowEscapeKey: false,
                    confirmButtonColor: '#4FB973',
                    confirmButtonText: 'Close',
                    allowOutsideClick: false
                });
            } catch (error) {
                setLoading(false);
                if (error.response && error.response.status === 409) {
                    setErrors({ apiError: true, errorMessage: error.response.data.message });
                    return;
                };
                setErrors({ apiError: true, errorMessage: 'Error at updating user.' });
                return;
            }
        };
    };

    const savePasswordDetails = async () => {
        try {
            setLoading(true);
            setErrors({ password: false, errorMessage: '' });
            const passPattern = /^(?=.*[0-9])(?=.*[!@#$%^&.,_*])[a-zA-Z0-9!@#$%^&.,_*]{8,16}$/;
            if (!passwordDetails.currentPassword) {
                setErrors({ password: true, errorMessage: 'Current password must be needed.' });
                setLoading(false);
                return;
            }
            if (!passwordDetails.newPassword) {
                setErrors({ password: true, errorMessage: 'New password must be needed.' });
                setLoading(false);
                return;
            }
            if (!passwordDetails.confirmPassword) {
                setErrors({ password: true, errorMessage: 'Confirm password must be needed.' });
                setLoading(false);
                return;
            }
            if (!passPattern.test(passwordDetails.newPassword)) {
                setErrors({ password: true, errorMessage: `Passwords must contain \n\u2022 a minimum of 1 lower case letter, \n\u2022 1 upper case letter, \n\u2022 1 numeric character \n\u2022 1 special character and \n\u2022 at least 8 characters in length.` });
                setLoading(false);
                return;
            } else if (passwordDetails.newPassword !== passwordDetails.confirmPassword) {
                setErrors({ password: true, errorMessage: 'New password and confirm password does not match.' });
                setLoading(false);
                return;
            }
            await handleChangePasswords();
            setPasswordDetails({ currentPassword: '', newPassword: '', confirmPassword: '' });
        } catch (error) {
            setErrors({ password: true, errorMessage: error.message });
            setLoading(false);
        }
    };

    const saveEmergencyContact = async (dataToUpdate, section) => {
        try {
            setLoading(true);
            await axios.put('/api/v2/users_emergency_contact/' + user._id, dataToUpdate);
            Swal.fire({
                icon: 'success',
                title: 'Changes has successfully updated',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(() => {
                handleLoadUser();
                setLoading(false);
                if (section === 'emergencyContact') {
                    setIsEditMainEmergencyContact(false);
                }
                if (section === 'additionalInformation') {
                    setIsEditAdditionalInformation(false);
                }
            });

        } catch (error) {
            setLoading(false);
            if (error.response && error.response.status === 409) {
                setErrors({ apiError: true, errorMessage: error.response.data.message });
                return;
            };
            setErrors({ apiError: true, errorMessage: 'Error at updating users emergency contact information.' });
            return;
        }
    };

    return (
        <>
            <MyAccountContainer>
                <CustomAlert id='error-alert' className='my-2 py-2 font info w-100 d-flex' style={{ 'maxWidth': '100%' }} variant="secondary">
                    <img src={AlertInfo} alt='AlertInfo' />
                    <h3 className='w-100 align-self-center  mb-0 ml-2'>Complete View and manage your contact, password, and emergency contact details; these details are used for all of your active locations.</h3>
                </CustomAlert>
                <CustomCard className='mt-4'>
                    <CustomCard.Body>
                        <div className="d-flex align-self-center">
                            <div className="align-items-center d-flex justify-content-center">
                                <NameBlockElement fontSize='2.5rem' height='5rem' firstName={user.firstName} lastName={user.lastName} />
                            </div>
                            <Heading className="ml-2">{user.firstName} {user.lastName}</Heading>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap">
                            <SubHeader className='font-weight-bold mt-3 mb-0'>Contact Information</SubHeader>
                            <div className="d-flex flex-wrap">
                                {!isEditContactInformation ?
                                    <Button type='button' variant='' className='text-success px-4' onClick={() => setIsEditContactInformation(true)}>
                                        <img className='cursor-pointer mr-2' src={pencilGreen} alt="Edit Arrow" />
                                        <strong>{'Edit'}</strong>
                                    </Button>
                                    :
                                    <>
                                        <Button variant='light' className="px-5 mx-2 text-success" onClick={() => cancel('contactInformation')}>Cancel</Button>
                                        <Button variant='success' disabled={loading || !isSaveEnabled || isEmailChanged || isPhoneChanged} className="px-5 ml-2" onClick={() => save('contactInformation')}>Save changes</Button>
                                    </>
                                }
                            </div>
                        </div>
                        <hr className="my-2" />
                        <CustomCard.Text>
                            {!isEditContactInformation ?
                                <AccountRow className='mt-4'>
                                    <Col lg={6} md={6} sm={12} >
                                        <span className='font-weight-bold d-inline-block w-8'>
                                            <img className='mr-2' src={User} alt="user" />
                                            First Name
                                        </span>
                                        <span>{userDetails.firstName}</span>
                                        <hr />
                                    </Col >
                                    <Col lg={6} md={6} sm={12} >
                                        <span className='font-weight-bold d-inline-block w-8'>
                                            <img className='mr-2' src={Phone} alt="phone" />
                                            Phone
                                        </span>
                                        <span>{userDetails.phone}</span>
                                        <hr />
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <span className='font-weight-bold d-inline-block w-8'>
                                            <img className='mr-2' src={User} alt="Edit Arrow" />Last Name
                                        </span>
                                        <span>{userDetails.lastName}</span>
                                        <hr className="mb-0" />
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <span className='font-weight-bold d-inline-block w-8'>
                                            <img className='mr-2' src={Email} alt="phone" />
                                            Email
                                        </span>
                                        <span>{userDetails.email}</span>
                                        <hr className="mb-0" />
                                    </Col>
                                </AccountRow>
                                :
                                <AccountRow className='mt-4'>
                                    <Col lg={6} md={6} sm={12}>
                                        <Input
                                            required={true}
                                            label='First Name'
                                            type='text'
                                            value={userDetails.firstName}
                                            onChange={(e) => onModelChange('contactInformation', 'firstName', e.target.value)}
                                            placeholder='Enter First Name'
                                        />
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <StyledLabel>Phone</StyledLabel>
                                        <div className="position-relative">
                                            <CustomPhoneInput
                                                countrySelectProps={{ unicodeFlags: true }}
                                                defaultCountry="US"
                                                smartCaret={true}
                                                countryCallingCodeEditable={false}
                                                international={true}
                                                autoComplete="off"
                                                placeholder="Enter phone number"
                                                onChange={(e) => onPhoneChange('phone', e)}
                                                onCountryChange={((e) => countryChange(e))}
                                                value={userDetails.phone}
                                                disabled={isEmailChanged}
                                            />
                                            {
                                                isPhoneChanged &&
                                                <VerifyContainer >
                                                    <StatusBadge  >
                                                        <div className="badge yellow">
                                                            <div className="text">
                                                                Verify Phone
                                                                <TransparentButton onClick={() => sendCode()} className='ml-1'>
                                                                    <img src={refreshIcon} alt='refresh icon' />
                                                                </TransparentButton>
                                                            </div>
                                                        </div>
                                                    </StatusBadge>
                                                </VerifyContainer>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Input
                                            required={true}
                                            label='Last Name'
                                            type='text'
                                            value={userDetails.lastName}
                                            onChange={(e) => onModelChange('contactInformation', 'lastName', e.target.value)}
                                            placeholder='Enter Last Name'
                                        />
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <StyledLabel>Email</StyledLabel>
                                        <div className="position-relative">
                                            <Input
                                                required={true}
                                                type='email'
                                                value={userDetails.email}
                                                onChange={(e) => onEmailChange('email', e.target.value)}
                                                placeholder='Enter Email'
                                                disable={isPhoneChanged}
                                            />
                                            {
                                                isEmailChanged &&
                                                < VerifyContainer >
                                                    <StatusBadge>
                                                        <div className="badge yellow">
                                                            <div className="text">
                                                                Verify Email
                                                                <TransparentButton onClick={() => sendCode()} className='ml-1'>
                                                                    <img src={refreshIcon} alt='refresh icon' />
                                                                </TransparentButton>
                                                            </div>
                                                        </div>
                                                    </StatusBadge>
                                                </VerifyContainer>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <h5 className='text-success my-3'> <img src={infoIcon} className='mr-2' />Either email or phone can be updated at a time.</h5>
                                    </Col>
                                    {(errors.firstName || errors.lastName || errors.phone || errors.email || errors.apiError || errors.invalid) &&
                                        <Alert id='error-alert' className='my-0 w-100 text-center' style={{ 'maxWidth': '100%' }} variant="danger">
                                            <p className='w-100 mb-0'>
                                                {errors.errorMessage}
                                            </p>
                                        </Alert>
                                    }
                                </AccountRow>
                            }
                        </CustomCard.Text>
                    </CustomCard.Body>
                </CustomCard>

                <CustomCard className='mt-5'>
                    <CustomCard.Body>
                        <div className="d-flex justify-content-between">
                            <SubHeader className='font-weight-bold mb-0'>Update Password</SubHeader>
                        </div>
                        <hr className="my-2" />
                        <CustomCard.Text>
                            <AccountRow className='mt-2'>
                                <Col lg={6} md={6} sm={12} >
                                    <div className="position-relative">
                                        <Input
                                            label='Current Password'
                                            type={showPassword.currentPassword ? 'text' : 'password'}
                                            name='currentPassword'
                                            onChange={(e) => onModelChange('passwordDetails', 'currentPassword', e.target.value)}
                                            value={passwordDetails.currentPassword}
                                            placeholder='Enter current password'
                                            required={true}
                                        />
                                        <img
                                            src={showPassword.currentPassword ? eyeIcon : eyeOffIcon}
                                            alt="Toggle Visibility"
                                            className="eye-icon position-absolute"
                                            onClick={() => togglePasswordVisibility('currentPassword')}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <Font14 className="mb-0 mt-4">Create New Password</Font14>
                                    <hr className="mt-1" />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <div className="position-relative">
                                        <Input
                                            label='New Password'
                                            type={showPassword.newPassword ? 'text' : 'password'}
                                            name='newPassword'
                                            onChange={(e) => onModelChange('passwordDetails', 'newPassword', e.target.value)}
                                            value={passwordDetails.newPassword}
                                            placeholder='Enter new password'
                                            required={true}
                                        />
                                        <img
                                            src={showPassword.newPassword ? eyeIcon : eyeOffIcon}
                                            alt="Toggle Visibility"
                                            className="eye-icon position-absolute"
                                            onClick={() => togglePasswordVisibility('newPassword')}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <div className="position-relative">
                                        <Input
                                            label='Confirm New Password'
                                            type={showPassword.confirmPassword ? 'text' : 'password'}
                                            name='confirmPassword'
                                            onChange={(e) => onModelChange('passwordDetails', 'confirmPassword', e.target.value)}
                                            value={passwordDetails.confirmPassword}
                                            placeholder='Enter new password'
                                            required={true}
                                        />
                                        <img
                                            src={showPassword.confirmPassword ? eyeIcon : eyeOffIcon}
                                            alt="Toggle Visibility"
                                            className="eye-icon position-absolute"
                                            onClick={() => togglePasswordVisibility('confirmPassword')}
                                        />
                                    </div>
                                </Col>
                                {(errors.password) &&
                                    <Alert id='error-alert' className='my-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                        <p className='w-100 mb-0'>
                                            {errors.errorMessage}
                                        </p>
                                    </Alert>
                                }
                                <Col xs={12} className="d-flex justify-content-end mt-2 flex-wrap">
                                    <Button variant='light' className="px-5 mx-2 text-success" onClick={() => cancel('passwordDetails')}>Cancel</Button>
                                    <Button variant='success' className="px-5 ml-2" onClick={() => save('passwordDetails')}>Set password</Button>
                                </Col>
                            </AccountRow>
                        </CustomCard.Text>
                    </CustomCard.Body>
                </CustomCard>

                <CustomCard className='mt-5'>
                    <CustomCard.Body>
                        <div className="d-flex justify-content-between flex-wrap">
                            <SubHeader className='font-weight-bold mb-0'>Emergency Contact Information</SubHeader>
                            <div className="d-flex flex-wrap">
                                {isEditMainEmergencyContact ?
                                    <>
                                        <Button variant='light' className="px-5 mx-2 text-success" onClick={() => cancel('emergencyContact')}>Cancel</Button>
                                        <Button variant='success' className="px-5 ml-2" onClick={() => save('emergencyContact')}>Save changes</Button>
                                    </>
                                    :
                                    <Button type='button' variant='' className='text-success px-4' onClick={() => setIsEditMainEmergencyContact(true)}>
                                        <img className='cursor-pointer mr-2' src={pencilGreen} alt="Edit Arrow" />
                                        <strong>{'Edit'}</strong>
                                    </Button>
                                }
                            </div>
                        </div>
                        <hr className="my-2" />
                        <CustomCard.Text>
                            <AccountRow className='mt-2'>
                                <Col lg={6} md={6} sm={12} className='row m-0'>
                                    <Col xs={12} className="mt-2 p-0">
                                        <EmergencyHeader >
                                            Main Contact
                                        </EmergencyHeader>
                                    </Col>
                                    {!isEditMainEmergencyContact ?
                                        <>
                                            <Col xs={12} className="mt-2 p-0">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={User} alt="user" />
                                                    Name
                                                </span>
                                                <span>{emergencyContactInformation.mainContact.name ? emergencyContactInformation.mainContact.name : ''}</span>
                                                <hr />
                                            </Col>
                                            <Col xs={12} className="mt-2 p-0">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={User} alt="user" />
                                                    Relationship
                                                </span>
                                                <span>{emergencyContactInformation.mainContact.relation ? emergencyContactInformation.mainContact.relation : ''}</span>
                                                <hr />
                                            </Col>
                                            <Col xs={12} className="p-0 mt-2">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={Phone} alt="phone" />
                                                    Phone
                                                </span>
                                                <span>{emergencyContactInformation.mainContact.phone ? emergencyContactInformation.mainContact.phone : ''}</span>
                                                <hr />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col xs={12} className="mt-2 p-0">
                                                <Input
                                                    label='Name'
                                                    type='text'
                                                    name='name'
                                                    onChange={(e) => onModelChange('emergencyContact', 'mainContact', { name: e.target.value })}
                                                    value={emergencyContactInformation.mainContact.name}
                                                    placeholder='Enter name'
                                                />
                                            </Col>
                                            <Col xs={12} className="mt-2 p-0">
                                                <Input
                                                    label='Relationship'
                                                    type='text'
                                                    name='relation'
                                                    onChange={(e) => onModelChange('emergencyContact', 'mainContact', { relation: e.target.value })}
                                                    value={emergencyContactInformation.mainContact.relation}
                                                    placeholder='Enter relationship'
                                                />
                                            </Col>
                                            <Col xs={12} className="p-0 mt-2">
                                                <StyledLabel>Phone</StyledLabel>
                                                <CustomPhoneInput
                                                    countrySelectProps={{ unicodeFlags: true }}
                                                    defaultCountry="US"
                                                    smartCaret={true}
                                                    countryCallingCodeEditable={false}
                                                    international={true}
                                                    autoComplete="off"
                                                    placeholder="Enter phone"
                                                    onChange={(e) => onModelChange('emergencyContact', 'mainContact', { phone: e })}
                                                    onCountryChange={((e) => countryChange(e))}
                                                    value={emergencyContactInformation.mainContact.phone}
                                                />
                                            </Col>
                                        </>
                                    }
                                    {(errors.mainContact) &&
                                        <Alert id='error-alert' className='my-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                            <p className='w-100 mb-0'>
                                                {errors.errorMessage}
                                            </p>
                                        </Alert>
                                    }
                                </Col>
                                <Col lg={6} md={6} sm={12} className=' m-0'>
                                    <Col xs={12} className="mt-2 p-0">
                                        <EmergencyHeader >
                                            Out-of-town Emergency Contact
                                        </EmergencyHeader>
                                    </Col>
                                    {!isEditMainEmergencyContact ?
                                        <>
                                            <Col xs={12} className="mt-2 p-0">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={User} alt="user" />
                                                    Name
                                                </span>
                                                <span>{emergencyContactInformation.outOfTownContact.name ? emergencyContactInformation.outOfTownContact.name : ''}</span>
                                                <hr />
                                            </Col>
                                            <Col xs={12} className="p-0 mt-2">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={Phone} alt="phone" />
                                                    Phone
                                                </span>
                                                <span>{emergencyContactInformation.outOfTownContact.phone ? emergencyContactInformation.outOfTownContact.phone : ''}</span>
                                                <hr />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col xs={12} className="mt-2 p-0">
                                                <Input
                                                    label='Name'
                                                    type='text'
                                                    name='name'
                                                    onChange={(e) => onModelChange('emergencyContact', 'outOfTownContact', { name: e.target.value })}
                                                    value={emergencyContactInformation.outOfTownContact.name}
                                                    placeholder='Enter name'
                                                />
                                            </Col>
                                            <Col xs={12} className="p-0 mt-2">
                                                <StyledLabel>Phone</StyledLabel>
                                                <CustomPhoneInput
                                                    countrySelectProps={{ unicodeFlags: true }}
                                                    defaultCountry="US"
                                                    smartCaret={true}
                                                    countryCallingCodeEditable={false}
                                                    international={true}
                                                    autoComplete="off"
                                                    placeholder="Enter phone"
                                                    onChange={(e) => onModelChange('emergencyContact', 'outOfTownContact', { phone: e })}
                                                    onCountryChange={((e) => countryChange(e))}
                                                    value={emergencyContactInformation.outOfTownContact.phone}
                                                />
                                            </Col>
                                        </>
                                    }
                                    {(errors.outOfTownContact) &&
                                        <Alert id='error-alert' className='my-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                            <p className='w-100 mb-0'>
                                                {errors.errorMessage}
                                            </p>
                                        </Alert>
                                    }
                                </Col>
                                <Col lg={6} md={6} sm={12} className='m-0'>
                                    <Col xs={12} className="mt-2 p-0">
                                        <EmergencyHeader >
                                            Physician Contact
                                        </EmergencyHeader>
                                    </Col>
                                    {!isEditMainEmergencyContact ?
                                        <>
                                            <Col xs={12} className="mt-2 p-0">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={Clinic} alt="user" />
                                                    Company
                                                </span>
                                                <span>{emergencyContactInformation.physicianContact.company ? emergencyContactInformation.physicianContact.company : ''}</span>
                                                <hr />
                                            </Col>
                                            <Col xs={12} className="mt-2 p-0">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={User} alt="user" />
                                                    Name
                                                </span>
                                                <span>{emergencyContactInformation.physicianContact.name ? emergencyContactInformation.physicianContact.name : ''}</span>
                                                <hr />
                                            </Col>
                                            <Col xs={12} className="mt-2 p-0">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={User} alt="user" />
                                                    Phone
                                                </span>
                                                <span>{emergencyContactInformation.physicianContact.phone ? emergencyContactInformation.physicianContact.phone : ''}</span>
                                                <hr />
                                            </Col>
                                            <Col xs={12} className="p-0 mt-2">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={Phone} alt="phone" />
                                                    Address
                                                </span>
                                                <span>{emergencyContactInformation.physicianContact.address ? emergencyContactInformation.physicianContact.address : ''}</span>
                                                <hr />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col xs={12} className="mt-2 p-0">
                                                <Input
                                                    label='Company'
                                                    type='text'
                                                    name='company'
                                                    onChange={(e) => onModelChange('emergencyContact', 'physicianContact', { company: e.target.value })}
                                                    value={emergencyContactInformation.physicianContact.company}
                                                    placeholder='Enter company name'
                                                />
                                            </Col>
                                            <Col xs={12} className="mt-2 p-0">
                                                <Input
                                                    label='Name'
                                                    type='text'
                                                    name='name'
                                                    onChange={(e) => onModelChange('emergencyContact', 'physicianContact', { name: e.target.value })}
                                                    value={emergencyContactInformation.physicianContact.name}
                                                    placeholder='Enter name'
                                                />
                                            </Col>
                                            <Col xs={12} className="p-0 mt-2">
                                                <StyledLabel>Phone</StyledLabel>
                                                <CustomPhoneInput
                                                    countrySelectProps={{ unicodeFlags: true }}
                                                    defaultCountry="US"
                                                    smartCaret={true}
                                                    countryCallingCodeEditable={false}
                                                    international={true}
                                                    autoComplete="off"
                                                    placeholder="Enter phone"
                                                    onChange={(e) => onModelChange('emergencyContact', 'physicianContact', { phone: e })}
                                                    onCountryChange={((e) => countryChange(e))}
                                                    value={emergencyContactInformation.physicianContact.phone}
                                                />
                                            </Col>
                                            <Col xs={12} className="mt-2 p-0">
                                                <Input
                                                    label='Address'
                                                    type='text'
                                                    name='address'
                                                    onChange={(e) => onModelChange('emergencyContact', 'physicianContact', { address: e.target.value })}
                                                    value={emergencyContactInformation.physicianContact.address}
                                                    placeholder='Enter address'
                                                />
                                            </Col>
                                        </>
                                    }
                                    {(errors.physicianContact) &&
                                        <Alert id='error-alert' className='my-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                            <p className='w-100 mb-0'>
                                                {errors.errorMessage}
                                            </p>
                                        </Alert>
                                    }
                                </Col>
                                <Col lg={6} md={6} sm={12} className='m-0'>
                                    <Col xs={12} className="mt-2 p-0">
                                        <EmergencyHeader >
                                            Pharmacy
                                        </EmergencyHeader>
                                    </Col>
                                    {!isEditMainEmergencyContact ?
                                        <>
                                            <Col xs={12} className="mt-2 p-0">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={Clinic} alt="user" />
                                                    Name
                                                </span>
                                                <span>{emergencyContactInformation.pharmacyContact.name ? emergencyContactInformation.pharmacyContact.name : ''}</span>
                                                <hr />
                                            </Col>
                                            <Col xs={12} className="mt-2 p-0">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={User} alt="user" />
                                                    Phone
                                                </span>
                                                <span>{emergencyContactInformation.pharmacyContact.phone ? emergencyContactInformation.pharmacyContact.phone : ''}</span>
                                                <hr />
                                            </Col>
                                            <Col xs={12} className="p-0 mt-2">
                                                <span className='font-weight-bold d-inline-block w-8'>
                                                    <img className='mr-2' src={Phone} alt="phone" />
                                                    Address
                                                </span>
                                                <span>{emergencyContactInformation.pharmacyContact.address ? emergencyContactInformation.pharmacyContact.address : ''}</span>
                                                <hr />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col xs={12} className="mt-2 p-0">
                                                <Input
                                                    label='Pharmacy name'
                                                    type='text'
                                                    name='name'
                                                    onChange={(e) => onModelChange('emergencyContact', 'pharmacyContact', { name: e.target.value })}
                                                    value={emergencyContactInformation.pharmacyContact.name}
                                                    placeholder='Enter name'
                                                />
                                            </Col>
                                            <Col xs={12} className="p-0 mt-2">
                                                <StyledLabel>Phone</StyledLabel>
                                                <CustomPhoneInput
                                                    countrySelectProps={{ unicodeFlags: true }}
                                                    defaultCountry="US"
                                                    smartCaret={true}
                                                    countryCallingCodeEditable={false}
                                                    international={true}
                                                    autoComplete="off"
                                                    placeholder="Enter phone"
                                                    onChange={(e) => onModelChange('emergencyContact', 'pharmacyContact', { phone: e })}
                                                    onCountryChange={((e) => countryChange(e))}
                                                    value={emergencyContactInformation.pharmacyContact.phone}
                                                />
                                            </Col>
                                            <Col xs={12} className="mt-2 p-0">
                                                <Input
                                                    label='Address'
                                                    type='text'
                                                    name='address'
                                                    onChange={(e) => onModelChange('emergencyContact', 'pharmacyContact', { address: e.target.value })}
                                                    value={emergencyContactInformation.pharmacyContact.address}
                                                    placeholder='Enter address'
                                                />
                                            </Col>
                                        </>
                                    }
                                    {(errors.pharmacyContact) &&
                                        <Alert id='error-alert' className='my-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                            <p className='w-100 mb-0'>
                                                {errors.errorMessage}
                                            </p>
                                        </Alert>
                                    }
                                </Col>
                                <Col lg={6} md={6} sm={12} className='row m-0'>
                                    <Col xs={12} className="mt-2 p-0">
                                        <EmergencyHeader >
                                            Hospital Preference
                                        </EmergencyHeader>
                                    </Col>
                                    {!isEditMainEmergencyContact ?
                                        <Col xs={12} className="mt-2 p-0">
                                            <span className='font-weight-bold d-inline-block w-8'>
                                                <img className='mr-2' src={Clinic} alt="user" />
                                                Name
                                            </span>
                                            <span>{emergencyContactInformation.hospitalPreference.name ? emergencyContactInformation.hospitalPreference.name : ''}</span>
                                            <hr />
                                        </Col>
                                        :
                                        <Col xs={12} className="mt-2 p-0">
                                            <Input
                                                label='Hospital name'
                                                type='text'
                                                name='name'
                                                onChange={(e) => onModelChange('emergencyContact', 'hospitalPreference', { name: e.target.value })}
                                                value={emergencyContactInformation.hospitalPreference.name}
                                                placeholder='Enter hospital name'
                                            />
                                        </Col>
                                    }
                                </Col>
                            </AccountRow>

                            <div className="d-flex justify-content-between mt-3 flex-wrap">
                                <SubHeader className='font-weight-bold mb-0'>Additional information</SubHeader>
                                <div className="d-flex flex-wrap">
                                    {isEditAdditionalInformation ?
                                        <>
                                            <Button variant='light' className="px-5 mx-2 text-success" onClick={() => cancel('additionalInformation')}>Cancel</Button>
                                            <Button variant='success' className="px-5 ml-2" onClick={() => save('additionalInformation')}>Save changes</Button>
                                        </>
                                        :
                                        <Button type='button' variant='' className='text-success px-4' onClick={() => setIsEditAdditionalInformation(true)}>
                                            <img className='cursor-pointer mr-2' src={pencilGreen} alt="Edit Arrow" />
                                            <strong>{'Edit'}</strong>
                                        </Button>
                                    }
                                </div>
                            </div>
                            <hr className="my-2" />
                            <AccountRow className='mt-2'>
                                <Col lg={6} md={6} sm={12} className='row m-0'>
                                    <Col xs={12} className="mt-2 p-0">
                                        <EmergencyHeader >
                                            Allergies
                                        </EmergencyHeader>
                                    </Col>
                                    {!isEditAdditionalInformation ?
                                        <Col xs={12} className="mt-2 p-0">
                                            <span className='font-weight-bold d-inline-block w-8'>
                                                <img className='mr-2' src={Allergies} alt="user" />
                                                Name
                                            </span>
                                            <span>{emergencyContactInformation.allergies.map(a => a.name).join(', ')}</span>
                                            <hr />
                                        </Col>
                                        :
                                        <Col xs={12} className="mt-2 p-0">
                                            <Input
                                                type="text"
                                                label='Name'
                                                onChange={handleChange}
                                                onBlur={handleBlurOrEnter}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleBlurOrEnter();
                                                        e.preventDefault();
                                                    }
                                                }}
                                                value={inputValue}
                                                placeholder="Ex. Food, Dust allergies"
                                            />
                                        </Col>
                                    }
                                </Col>
                                <Col lg={6} md={6} sm={12} className='row m-0'>
                                    <Col xs={12} className="mt-2 p-0">
                                        <EmergencyHeader >
                                            Religious Organization
                                        </EmergencyHeader>
                                    </Col>
                                    {!isEditAdditionalInformation ?
                                        <Col xs={12} className="mt-2 p-0">
                                            <span className='font-weight-bold d-inline-block w-8'>
                                                <img className='mr-2' src={Religious} alt="user" />
                                                Name
                                            </span>
                                            <span>{emergencyContactInformation.religiousOrganization.name}</span>
                                            <hr />
                                        </Col>
                                        :
                                        <Col xs={12} className="mt-2 p-0">
                                            <Input
                                                label='Religious Organization name'
                                                type='text'
                                                name='name'
                                                onChange={(e) => onModelChange('additionalInformation', 'religiousOrganization', { name: e.target.value })}
                                                value={emergencyContactInformation.religiousOrganization.name}
                                                placeholder='Enter name'
                                            />
                                        </Col>
                                    }
                                </Col>
                                <Col xs={12} className="d-flex mt-3">
                                    <span className='font-weight-bold d-inline-block w-8 align-self-center'>
                                        Last updated:
                                    </span>
                                    <TimeStamp className="rounded-3 text-success">{moment(user.emergencyContactUpdatedAt).format('MM/DD/YYYY') || '--'}</TimeStamp>
                                </Col>
                            </AccountRow>
                        </CustomCard.Text>
                    </CustomCard.Body>
                </CustomCard>
            </MyAccountContainer >

            <Modal show={isOpenVerifyOtpModal} onHide={handleClose} centered>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        <Heading>OTP Verification</Heading>
                        <SubHeading className="px-5">We just sent a code to your {isEmailChanged ? 'email' : 'phone'}. Please check and enter the code here.</SubHeading>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center" >
                    <OtpContainer>
                        <div>
                            <FormLabel>{isEmailChanged ? 'Email' : 'Phone'} OTP</FormLabel>
                            <OtpInput
                                inputType='text'
                                inputStyle='otpInput'
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                renderSeparator={<span className="mx-4">-</span>}
                                renderInput={(props) => <FormInput {...props} />}
                            />
                            <p className="mt-2 text-right">Didn’t receive a code? <span onClick={() => sendCode()} className="text-success cursor-pointer">Resend code</span></p>
                        </div>
                    </OtpContainer>
                </Modal.Body>
                <Modal.Footer className="border-0 d-block">
                    <Button disabled={(otp.length !== 4) || loading} type='button' onClick={() => verifyOtp()} size="lg" className="w-100 mt-5" variant="success">{loading ? 'Loading...' : 'Verify'}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BasicDetails;
