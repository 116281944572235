import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from "axios";
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { BillingProductContainer, Chip, ErrorContainer, FormInput, FormLabel, HeaderContainer, Heading, PaymentInfoContainer, PriceSubTotal, PriceTotal, ProductCard, ProductCardSubTitle, ProductCardTitle, ProductCardTitleContainer, SectionTitle, SubHeading } from "./Style";
import { DropDownStyle, states } from "../../helpers";

import InfoIcon from '../../assets/images/info-green.svg';
import { Checkbox } from "../../components";
import { addBillingDetails, updateOnboardUser } from "../../actions";


const BillingInfo = ({ }) => {

    const currentUser = useSelector(state => state.user.currentUser);
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const dispatch = useDispatch();

    const [cardHolderName, setCardHolderName] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [appliedCoupon, setAppliedCoupon] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [priceDetails, setPriceDetails] = useState({ basePrice: 0, discountPrice: 0, remaining: 0 });
    const [saveInprogress, setSaveInprogress] = useState(false);
    const [error, setError] = useState('');
    const [address, setAddress] = useState({ address: '', address2: '', city: '', state: '', zip: '' });
    const [sameAddress, setSameAddress] = useState(false);
    const [validated, setValidated] = useState(false);
    const [plans, setPlans] = useState([
        { title: 'OSHA+SDS', subTitle: 'Comprehensive OSHA compliance with digital Safety Data Sheet management.', price: 0, planType: 'OSHA&SDS' },
        { title: 'OSHA', subTitle: 'Comprehensive OSHA compliance', price: 0, planType: 'OSHA' },
    ]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPlanDetails();
    }, []);

    const getPlanDetails = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get('/api/v2/planDetails');
            const tempPlans = plans.map((plan) => {
                const { unit_amount } = data[plan.planType];
                plan.price = unit_amount / 100;
                return plan;
            });
            setPlans(tempPlans);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getPlanDetails ~ error:", error);
            setLoading(false);
        }
    };

    const onChange = (name, value) => {
        const tempAddress = { ...address };
        if (name === 'zip' && value && value.length > 5) {

        } else {
            tempAddress[name] = value;
            setAddress(tempAddress);
        }
    };

    const calculatePrice = (selectedPlanName, discount, amountOff) => {
        const plan = plans.find(p => p.planType === selectedPlanName);
        if (!plan) return;
        const basePrice = plan.price;
        const discountPrice = (discount * basePrice) / 100;
        const remaining = (basePrice - discountPrice) - amountOff;
        setPriceDetails({ basePrice, discountPrice, remaining });
    };

    const removeCoupon = () => {
        calculatePrice(selectedPlan, 0, 0);
        setCouponCode('');
        setAppliedCoupon('');
    };

    const submitBillingInfo = async (event) => {
        event.preventDefault();
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            try {
                if (!address.zip || address.zip.trim().length !== 5) return toast.error('Please enter a 5 digit zip code');
                if (!selectedPlan) return toast.error('Select the subscription plan');

                setSaveInprogress(true);
                const cardNumberElement = elements.getElement(CardNumberElement);
                const { token, error } = await stripe.createToken(cardNumberElement);
                if (error) {
                    toast.error(error.message);
                    setError(error.message);
                    setSaveInprogress(false);
                    return
                };
                setError('');
                await addBillingDetails({ address, selectedPlan, token: token.id, locationId: currentUser.location._id, cardHolderName, promoCode }, currentUser);
                await dispatch(updateOnboardUser({ isRegistrationCompleted: 2 }));
                setSaveInprogress(false);
                history.push('/');
            } catch (error) {
                setSaveInprogress(false);
                console.log("🚀 ~ file: NewBillingInfo.jsx:148 ~ submitBillingInfo ~ error:", error);
            }
        };
    };

    const selectPlan = (selectedPlanName) => {
        setCouponCode('');
        setAppliedCoupon('');
        setSelectedPlan(selectedPlanName);
        calculatePrice(selectedPlanName, 0, 0);
    };

    const handleSameAddress = () => {
        if (!sameAddress) {
            const { address } = currentUser.location;
            setSameAddress(true);
            setAddress({ address: address.address, address2: address.address2, city: address.city, state: address.state, zip: address.zip });
        } else {
            setSameAddress(false);
            setAddress({ address: '', address2: '', city: '', state: '', zip: '' });
        }
    };

    const applyCoupon = async () => {
        try {
            if (selectedPlan) {
                const { data } = await axios.post(`/api/v2/checkCoupon`, { couponCode, selectedPlan, amount: priceDetails.basePrice });
                if (data && data.valid) {
                    setAppliedCoupon(couponCode);
                    setPromoCode(data.id);
                    const discount = data ? data.percent_off : 0;
                    const amountOff = data.amount_off ? data.amount_off / 100 : 0;
                    calculatePrice(selectedPlan, discount, amountOff);
                } else if (data && !data.valid) {
                    removeCoupon();
                    toast.error('This coupon is not valid');
                };
            } else {
                removeCoupon();
                toast.error('Select billing cycle first.');
            };
        } catch (error) {
            removeCoupon();
            toast.error('This coupon is not valid');
        }
    };

    return (
        <Row className="w-100 mt-5">
            <Col lg='4' md='12' sm='12'>
                <BillingProductContainer>
                    <SectionTitle><h2>Choose your plan</h2></SectionTitle>
                    {loading ?
                        <ProductCard className="text-center"><Spinner animation="border" variant="success" /></ProductCard>
                        :
                        plans.map((plan) =>
                            <ProductCard selected={plan.planType === selectedPlan} key={plan.planType} onClick={() => selectPlan(plan.planType)}>
                                <ProductCardTitleContainer>
                                    <ProductCardTitle>{plan.title}</ProductCardTitle>
                                    <ProductCardSubTitle>{plan.subTitle}</ProductCardSubTitle>
                                </ProductCardTitleContainer>
                                <ProductCardTitle>{plan.price.toFixed(0)} <small>/year</small></ProductCardTitle>
                            </ProductCard>
                        )}
                </BillingProductContainer>
            </Col>
            <Col lg='8' md='12' sm='12'>
                <PaymentInfoContainer>
                    <HeaderContainer>
                        <Heading>Payment information</Heading>
                        <SubHeading>Complete your purchase by providing your payment details</SubHeading>
                    </HeaderContainer>
                    <Form noValidate validated={validated} onSubmit={submitBillingInfo}>
                        <Row className="mt-5">
                            <Col lg='12' md='12' sm='12' className='mb-3'>
                                <SectionTitle>Card Details</SectionTitle>
                            </Col>
                            <Col lg='4' md='6' sm='12'>
                                <FormLabel>Cardholder Name  <span className="text-danger">*</span></FormLabel>
                                <FormInput value={cardHolderName} onChange={(e) => setCardHolderName(e.target.value)} required type="text" placeholder="Enter your cardholder name" />
                                <ErrorContainer type="invalid">Required</ErrorContainer>
                            </Col>
                            <Col lg='4' md='6' sm='12'>
                                <FormLabel>Card Number  <span className="text-danger">*</span></FormLabel>
                                <CardNumberElement required={true} id="number" options={{ showIcon: true }} />
                            </Col>
                            <Col lg='2' md='6' sm='12'>
                                <FormLabel>Expiration  <span className="text-danger">*</span></FormLabel>
                                <CardExpiryElement />
                            </Col>
                            <Col lg='2' md='6' sm='12'>
                                <FormLabel>CVC <span className="text-danger">*</span></FormLabel>
                                <CardCvcElement />
                            </Col>
                            <Col lg='12' md='12' sm='12' className='my-4'>
                                <SectionTitle className="d-flex justify-content-between">Billing Address
                                    <FormLabel>
                                        <Checkbox onChange={handleSameAddress} name='sameAddress' checked={sameAddress} />
                                        <span className="ml-1"> Same as Street Address</span>
                                    </FormLabel></SectionTitle>
                            </Col>
                            <Col lg='4' md='6' sm='12'>
                                <FormLabel>Address  <span className="text-danger">*</span></FormLabel>
                                <FormInput onChange={(e) => onChange('address', e.target.value)} required value={address.address} type="text" placeholder="Enter address" />
                                <ErrorContainer type="invalid">Required</ErrorContainer>
                            </Col>
                            <Col lg='3' md='6' sm='12'>
                                <FormLabel>City  <span className="text-danger">*</span></FormLabel>
                                <FormInput onChange={(e) => onChange('city', e.target.value)} required value={address.city} type="text" placeholder="Enter city" />
                                <ErrorContainer type="invalid">Required</ErrorContainer>
                            </Col>
                            <Col lg='3' md='6' sm='12'>
                                <FormLabel>State <span className="text-danger">*</span></FormLabel>
                                <Select
                                    placeholder="Choose State"
                                    onChange={(e) => onChange('state', e.value)}
                                    options={states}
                                    menuPosition="fixed"
                                    styles={DropDownStyle}
                                    required={true}
                                    value={states.find(loc => loc.value === address.state)}
                                />
                                <ErrorContainer type="invalid">Required</ErrorContainer>
                            </Col>
                            <Col lg='2' md='6' sm='12'>
                                <FormLabel>Zip Code <span className="text-danger">*</span></FormLabel>
                                <FormInput onChange={(e) => onChange('zip', e.target.value)} required value={address.zip} type="text" placeholder="Enter zip code" />
                                <ErrorContainer type="invalid">Required</ErrorContainer>
                            </Col>
                            <Col lg='12' md='12' sm='12' className='my-4'>
                                <SectionTitle></SectionTitle>
                            </Col>
                            <Col lg='8' md='12' sm='12'>
                                <FormLabel>Referral code</FormLabel>
                                <div className="d-flex justify-content-between">
                                    <FormInput value={couponCode} onChange={(e) => setCouponCode(e.target.value)}
                                        type="text" placeholder="Referral code" /> <Button type="button" onClick={applyCoupon} variant="success" size='lg' className="ml-2 px-4">Apply</Button>
                                </div>
                                {appliedCoupon &&
                                    <Chip className="mt-2">
                                        <span>{appliedCoupon}</span><i onClick={removeCoupon} className='fa fa-times'></i>
                                    </Chip>
                                }
                            </Col>
                            <Col lg='12' md='12' sm='12' className='my-4'>
                                <SectionTitle></SectionTitle>
                            </Col>
                            <PriceTotal lg='12' md='12' sm='12' >
                                <p>Total</p>
                                {appliedCoupon ?
                                    <div>
                                        <PriceSubTotal className="total-with-discount">${priceDetails.basePrice}</PriceSubTotal>
                                        <p>${priceDetails.remaining}</p>
                                    </div> :
                                    <p>${priceDetails.basePrice}</p>}
                            </PriceTotal>
                        </Row>
                        <Row>
                            <Col lg='8' md='12' sm='12'>
                                <FormLabel><img src={InfoIcon} alt="" /> Your subscription renews annually. By continuing with your subscription and payment, you confirm that you agree to Ocoord’s terms and conditions.</FormLabel>
                            </Col>
                            <Col lg='4' md='12' sm='12' >
                                <Button disabled={saveInprogress} type="submit" variant="success" size='lg' className="w-100">{saveInprogress ? 'Loading...' : 'Subscribe & Pay'}</Button>
                            </Col>
                        </Row>
                    </Form>
                </PaymentInfoContainer>
            </Col>
        </Row>
    )
}

export default BillingInfo;