import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import defaultPoster from '../../assets/images/training_video_bg.png';
import { apiUrl } from "../../helpers";
import { TrainingProgressBar } from "../../components";


const NavHeader = styled.h2`
    padding:0.875rem;
    margin:0;
    @media screen and (max-device-width:4000px) and (orientation: portrait) {
        margin-left: 1.5rem;
    }
    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin-left: 1.5rem;
    }
`;

const NavigationTabSection = styled(Row)`
    color:#676767 !important;
    margin-bottom: 1rem!important;
    margin-right: 0px !important; 
    margin-left: 0px !important;
    line-height: 30px;
`;

const NavTab = styled(Col)`
    font-size: 1.2rem;
    font-weight: 600 !important;
    text-align: center;
    cursor:pointer;
    border-bottom: 1px solid #676767;
    @media screen and (max-device-width: 1024px) and (orientation: portrait) {
        line-height: 3rem;
        white-space: pre;
    }
    @media screen and (max-device-width:1024px) and (orientation: landscape) {
        line-height: 3rem;
        white-space: pre;
    }
    ${(props) => !props.disable &&
        ':hover{ background: #e3ffec !important;}'
    }
    
    &.active {
        color:#42A764 !important;
        border-bottom: 2px solid #42A764;
    }
`;

const TrainingContainer = styled.div`
    margin: 6.5rem 0.5rem 0rem 6rem !important;
    width: 100%;
    align-self: flex-start;
    align-items: flex-start;
    padding: 0;
`;

const ProgressContainer = styled.div`
    border-radius: 8px;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border: 1px solid #b9b7b7!important;
`;

const TrainingBody = styled.div`
    border-radius: 8px;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border: 1px solid  #b9b7b7!important;
    .fade-in {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }
      
    .fade-out {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }
`;

export const VideoTile = styled.div`
    border-radius: 8px;
    background-color: var(--background-color);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${(props) => props.bgImage}');
    min-height: 13vw;
    cursor: pointer;
    border: 1px #7afb7a solid;
    box-shadow: 0 1px 4px 0 #87b187;
`;

export const CustomCard = styled.div`
    border-radius: 10px !important;
    margin-bottom: 0.5rem!important;
`;

export const VideoTitle = styled.p`
    font-size: 16px;
    font-weight: 600;
`;

export const VideoDuration = styled.p`
    color:#676767;
`;

const TrainingList = () => {

    const user = useSelector(state => state.user.currentUser);
    const [trainingData, setTrainingData] = useState({ all: [], osha: [], hippa: [], newHire: [], newHireTrainingMinutesWatched: 0, trainingMinutesWatched: 0, trainingMinutesResetAt: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [activeTab, setActiveTab] = useState('all');
    const history = useHistory();

    useEffect(() => {
        getTrainingVideos();
    }, []);

    const getTrainingVideos = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/training/${user._id}/${user.practice._id}`);
            setTrainingData(payload);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ file: TrainingList.jsx:62 ~ getTrainingVideos ~ error:", error);
            setLoading(false);
            setError('Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.')
        }
    };

    const selectTab = (tab) => {
        setLoading(true);
        setActiveTab(tab);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const viewVideo = (video) => {
        history.push('training/view?videoId=' + encodeURIComponent(video.eTag));
    };

    return (
        <TrainingContainer>
            <ProgressContainer className='mx-0 my-3 py-2 px-4 w-100'>
                {loading ?
                    <div className='w-100 my-3 p-4 text-center'>
                        <Spinner animation="border" variant="success" />
                    </div>
                    :
                    <div className="d-flex justify-content-between">
                        <TrainingProgressBar trainingData={trainingData} />
                        {user.isAdmin &&
                            <div className="d-flex align-items-center">
                                <Button disabled={false} onClick={() => history.push('training/group/list')} size="lg" style={{ height: 'fit-content' }} variant='success'>Group Training</Button>
                            </div>
                        }
                    </div>
                }
            </ProgressContainer>
            <TrainingBody>
                <Row className="mx-0 px-3 w-100">
                    <NavHeader className="w-100 mt-2">Training Videos</NavHeader>
                    <NavigationTabSection className="mx-0 px-3 w-100">
                        <NavTab onClick={() => selectTab('all')} className={activeTab === 'all' ? 'active' : ''} lg='4' sm='4' md='4' title="All" >
                            All
                        </NavTab>
                        <NavTab onClick={() => selectTab('osha')} className={activeTab === 'osha' ? 'active' : ''} lg='4' sm='4' md='4' title="OSHA" >
                            OSHA
                        </NavTab>
                        {user.newHire ?
                            <NavTab onClick={() => selectTab('newHire')} className={activeTab === 'newHire' ? 'active' : ''} lg='4' sm='4' md='4' title="New Hire" >
                                New Hire
                            </NavTab>
                            :
                            <NavTab disable={'true'} lg='4' sm='4' md='4'></NavTab>
                        }
                    </NavigationTabSection>
                </Row>
                {loading ? <div className='w-100 my-3 p-4 text-center'>
                    <Spinner animation="border" variant="success" />
                </div>
                    :
                    <Row className={loading ? 'fade-out mx-0 px-3 py-5 w-100' : 'fade-in mx-0 px-3 py-5 w-100'} style={{ minHeight: '30vh' }} >
                        {trainingData[activeTab].map((video, index) =>
                            <Col className="" lg={4} sm={12} md={6} key={index}>
                                <CustomCard>
                                    <VideoTile onClick={() => viewVideo(video)} bgImage={video.poster ? apiUrl('/api/v2/trainingPoster/' + encodeURIComponent(video.poster.replaceAll('/', '~|~'))) : defaultPoster} />
                                    <VideoTitle className="mt-2 mx-2 mb-0">{video.title && video.title.indexOf('/') > -1 ? video.title.slice(video.title.indexOf('/') + 1) : video.title}</VideoTitle>
                                    <VideoDuration className="mx-2 mt-0 mb-3">You've watched {(video.currentTime / 60).toFixed(2)} min out of {(video.duration / 60).toFixed(2)} min</VideoDuration>
                                </CustomCard>
                            </Col>
                        )}
                    </Row>
                }
            </TrainingBody>

        </TrainingContainer>
    )
};

export default TrainingList;