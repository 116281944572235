import { useState } from "react";
import { SectionBlock } from "./Style";
import { Table, Col } from "react-bootstrap";
import Select from 'react-select';
import { DropDownStyle } from '../../helpers'

export const AssignedTabTable = () => {
    const [recordLoading, setRecordLoading] = useState(false);

    return (
        <div>
            {recordLoading ?

                <Col lg={12} style={{ minHeight: '30px' }} className='text-center'>
                    <Spinner animation="border" variant="success" />
                </Col>
                :
                <SectionBlock>
                    <div className="mb-2">
                        <span className='font-weight-bold d-inline-block'>
                            Location:
                        </span>
                        <span className='font-weight-bold d-inline-block '>
                            <Select
                                styles={DropDownStyle}
                            // defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                            // value={pageSizeOptions.filter((val) => val.value === pageSize)}
                            // options={pageSizeOptions}
                            // onChange={(e) => this.handlePageSizeChange(e.value)}
                            />
                        </span>
                    </div>
                    <div className="responsive-table">
                        <Table>
                            <thead>
                                <tr>
                                    <th className='w-25 pl-4'>Task Name</th>
                                    <th className='w-25'>Task Frequency</th>
                                    <th className='w-25'>Due Next</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td className='w-25'>
                                        <a href="">Needlestick Risk Assessment</a></td>
                                    <td className='w-25 pl-4'>Yearly</td>
                                    <td className='w-25'>06/05/23</td>
                                </tr>
                                <tr >
                                    <td className='w-25'>
                                        <a href="">Needlestick Risk Assessment</a></td>
                                    <td className='w-25 pl-4'>Yearly</td>
                                    <td className='w-25'>06/05/23</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </SectionBlock>
            }

        </div>
    );
};

