
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomAlert, SectionBlock, SectionHeading, MyAccountContainer } from "../Account/Style";
import AlertInfo from '../../assets/my-account/alert_info.svg';
import Upload from '../../assets/my-account/upload.svg';
import { Table, Button, Alert, Col, Spinner } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import UploadHealthDocuments from "./uploadHealthDocument";

const HealthDocuments = () => {
    const user = useSelector((state) => state.user.currentUser);
    const [docUploadOpen, setDocOpenUpload] = useState(false);
    const [staffDetails, setStaffDetails] = useState({});
    const [healthRecordType, setHealthRecordType] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(user.location._id);
    const [recordLoading, setRecordLoading] = useState(false);
    const [recordList, setRecordList] = useState([]);

    useEffect(() => {
        getHealthRecords(selectedLocation);
    }, []);

    const onUploadDocClose = () => {
        setDocOpenUpload(false);
        getHealthRecords(selectedLocation);
    };

    const getHealthRecords = async (locationId) => {
        try {
            setRecordLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/user_health_record/${user._id}/${locationId}`);
            setRecordList(payload);
            setRecordLoading(false);
        } catch (error) {
            setRecordLoading(false);
            console.log("🚀 ~ getHealthRecords ~ error:", error);
        }
    };

    return (
        <MyAccountContainer>
            <CustomAlert id='error-alert' className='my-2 py-2 font info w-100 d-flex' style={{ 'maxWidth': '100%' }} variant="secondary">
                <img src={AlertInfo} alt='AlertInfo' />
                <h3 className='w-100 align-self-center  mb-0 ml-2'>View your Role and manage your PPE at each of your current active locations.</h3>
            </CustomAlert>
            <div >
                <SectionBlock className="mt-4">
                    <SectionHeading className="flex-wrap">Health Records
                        <Button type='button' variant='success'
                            className='px-4 cursor-pointer mb-2' onClick={() => setDocOpenUpload(true)}>
                            <img className=' mr-3' src={Upload} alt="" />
                            Upload Health Document
                        </Button>
                    </SectionHeading>
                    {recordLoading ?
                        <Col lg={12} style={{ minHeight: '30px' }} className='text-center'>
                            <Spinner animation="border" variant="success" />
                        </Col>
                        :
                        <div className="responsive-table">
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='w-25 pl-4'>Name</th>
                                        <th className='w-25'>Date Added</th>
                                        <th className='w-25'>Uploaded By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recordList.length ?
                                        recordList.map((record) =>
                                            <tr key={record._id}>
                                                {/* <td className='w-25 pl-4'>{record.healthDocType || 'Other'}</td> */}
                                                <td className='w-25'>{record.name}</td>
                                                <td className='w-25'>{moment(record.completedAt).format('MM/DD/YYYY')}</td>
                                                <td className='w-25'>{record.completedBy.fullName}</td>
                                            </tr>
                                        ) :
                                        <tr>
                                            <td colSpan='4'>
                                                <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                                                    <h3 className='w-100 mb-0 text-center'>
                                                        No health records have been uploaded for this user at this location yet
                                                    </h3>
                                                </Alert>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    }
                </SectionBlock>
                {docUploadOpen &&
                    <UploadHealthDocuments close={setDocOpenUpload} afterSave={onUploadDocClose} show={docUploadOpen} user={user} />}
            </div>
        </MyAccountContainer>
    );
};

export default HealthDocuments;