import { useState } from "react";
import { useSelector } from "react-redux";
import { CustomAlert, SectionBlock, CustomCard, SubHeader, NavTab, NavigationTabSection, MyAccountContainer } from "../Account/Style";
import AlertInfo from '../../assets/my-account/alert_info.svg';
import { AssignedTabTable } from './AssignedTab'
import { RecordTabTable } from './RecordsTab'

const TasksAndRecords = () => {
    const user = useSelector((state) => state.user.currentUser);
    const [activeTab, setActiveTab] = useState('Assigned');


    const selectTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <MyAccountContainer>
            <CustomAlert id='error-alert' className='my-2 py-2 font info w-100 d-flex' style={{ 'maxWidth': '100%' }} variant="secondary">
                <img src={AlertInfo} alt='AlertInfo' />
                <h3 className='w-100 align-self-center  mb-0 ml-2'>
                    View your currently Assigned Tasks and past Records.
                </h3>
            </CustomAlert>
            <CustomCard className='mt-4'>
                <CustomCard.Body>
                    <SubHeader className='font-weight-bold mb-0'>Tasks</SubHeader>
                    <hr />
                    <NavigationTabSection className="mx-0 px-3 w-100">
                        <NavTab lg='4' sm='4' md='4' title="All" onClick={() => selectTab('Assigned')} className={activeTab === 'Assigned' ? 'active' : ''}>
                            Assigned
                        </NavTab>
                        <NavTab onClick={() => selectTab('Records')} className={activeTab === 'Records' ? 'active' : ''} lg='4' sm='4' md='4' title="Record" >
                            Records
                        </NavTab>
                    </NavigationTabSection>
                    {activeTab === 'Assigned' ?
                        <div>
                            <AssignedTabTable />
                        </div> :
                        <RecordTabTable />}
                </CustomCard.Body>
            </CustomCard>
        </MyAccountContainer>
    );
};

export default TasksAndRecords;