import { useState } from "react";
import { useSelector } from "react-redux";
import { Figure, Col, Row, Accordion, Card, Button, Table } from 'react-bootstrap';
import { NavTab, NavigationTabSection, CustomCardClick, CardBody, IconContainer, ImageContainer, TimeStamp, EquipmentRecords } from './Style';
import { Checkbox } from '../../components/Checkbox';
// image imports
import HeavyDutyGloves from '../../assets/safety-equipment/heavyDutyGloves.svg';
import SafetyGoggles from '../../assets/safety-equipment/safetyGoggles.svg';
import LatexGloves from '../../assets/safety-equipment/latexGloves.svg';
import Mask from '../../assets/safety-equipment/mask.svg';
import FluidResistantGown from '../../assets/safety-equipment/fluidResistantGown.svg';

const PersonalProtectiveEquipment = () => {
    const user = useSelector((state) => state.user.currentUser);
    const [activeTab, setActiveTab] = useState('Current Equipment');


    const selectTab = (tab) => {
        setActiveTab(tab);
    };
    return (
        <div>
            <NavigationTabSection className="mx-0 px-3 w-100">
                <NavTab lg='4' sm='4' md='4' title="All" onClick={() => selectTab('Current Equipment')} className={activeTab === 'Current Equipment' ? 'active' : ''}>
                    Current Equipment
                </NavTab>
                <NavTab onClick={() => selectTab('Records')} className={activeTab === 'Records' ? 'active' : ''} lg='4' sm='4' md='4' title="Records" >
                    Records
                </NavTab>
            </NavigationTabSection>
            {activeTab === 'Current Equipment' ?
                <Row>
                    <Col xs={12} md={6} className="mb-3">
                        <CustomCardClick >
                            <CardBody className="position-relative">
                                <Checkbox
                                    // onChange={(e) => {
                                    //     this.handleChange(e);
                                    //     onChange(e);
                                    // }}
                                    name='test'
                                    disabled={false}
                                    className='position-absolute checkbox'
                                />
                                <IconContainer>
                                    <ImageContainer
                                        width={50}
                                        height={50}
                                        alt="no"
                                        src={HeavyDutyGloves}
                                    />
                                    <Figure.Caption className='w-100 textAlign align-self-center'>
                                        <div className="charcoal-blue">
                                            <h5>
                                                Heavy-Duty Gloves
                                            </h5>
                                        </div>
                                    </Figure.Caption>
                                </IconContainer>
                            </CardBody>
                        </CustomCardClick>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                        <CustomCardClick >
                            <CardBody>
                                <IconContainer>
                                    <ImageContainer
                                        width={50}
                                        height={50}
                                        alt="no"
                                        src={SafetyGoggles}
                                    />
                                    <Figure.Caption className='w-100 textAlign align-self-center'>
                                        <div className="charcoal-blue">
                                            <h5>
                                                Safety Goggles
                                            </h5>
                                        </div>
                                    </Figure.Caption>
                                </IconContainer>
                            </CardBody>
                        </CustomCardClick>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                        <CustomCardClick >
                            <CardBody>
                                <IconContainer>
                                    <ImageContainer
                                        width={50}
                                        height={50}
                                        alt="no"
                                        src={LatexGloves}
                                    />
                                    <Figure.Caption className='w-100 textAlign align-self-center'>
                                        <div className="charcoal-blue">
                                            <h5>
                                                Latex Gloves
                                            </h5>
                                        </div>
                                    </Figure.Caption>
                                </IconContainer>
                            </CardBody>
                        </CustomCardClick>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                        <CustomCardClick >
                            <CardBody>
                                <IconContainer>
                                    <ImageContainer
                                        width={50}
                                        height={50}
                                        alt="no"
                                        src={Mask}
                                    />
                                    <Figure.Caption className='w-100 textAlign align-self-center'>
                                        <div className="charcoal-blue">
                                            <h5>
                                                Mask
                                            </h5>
                                        </div>
                                    </Figure.Caption>
                                </IconContainer>
                            </CardBody>
                        </CustomCardClick>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                        <CustomCardClick >
                            <CardBody>
                                <IconContainer>
                                    <ImageContainer
                                        width={50}
                                        height={50}
                                        alt="no"
                                        src={FluidResistantGown}
                                    />
                                    <Figure.Caption className='w-100 textAlign align-self-center'>
                                        <div className="charcoal-blue">
                                            <h5>
                                                Fluid-Resistant Gown
                                            </h5>
                                        </div>
                                    </Figure.Caption>
                                </IconContainer>
                            </CardBody>
                        </CustomCardClick>
                    </Col>
                </Row>
                :
                // <></>
                <EquipmentRecords >
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0" className="text-success">
                                    <span></span>
                                    10/05/2024
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className="responsive-table">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className='w-25 pl-4'>Equipment</th>
                                                    <th className='w-25'>Updated by </th>
                                                    <th className='w-25'>Updated Date </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td>test</td>
                                                    <td></td>
                                                    <td>test</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </EquipmentRecords>
            }
            <div className="col-12 d-flex mt-3">
                <span className='font-weight-bold d-inline-block w-8 align-self-center'>
                    Last updated:
                </span>
                <TimeStamp className="rounded-3 text-success">10/05/2024</TimeStamp>
            </div>
        </div>
    );
};

export default PersonalProtectiveEquipment;