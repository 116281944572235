import { Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { CustomErrorContainer, ErrorContainer, FormContainer, FormField, FormInput, FormLabel, HeaderContainer, Heading, PersonalInfoContainer, SubHeading } from "./Style";
import { validateEmail } from "../../helpers";
import { registerNewAccount } from "../../actions";

const PersonalInfo = ({ setStepNumber }) => {
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', email: '', password: '', confirmPassword: '' });
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataError, setDataError] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();

    const onChange = (name, value) => {
        const tempUserInfo = { ...userInfo };
        tempUserInfo[name] = value.trimStart();
        setUserInfo(tempUserInfo);
    };

    const validateData = () => {

        const error = {};
        if (!validateEmail(userInfo.email)) {
            error.email = 'Invalid email address.';
            error.in_valid = true;
        };
        const passPattern = /^(?=.*[0-9])(?=.*[!@#$%^&.,_*])[a-zA-Z0-9!@#$%^&.,_*]{8,16}$/;
        if (!passPattern.test(userInfo.password)) {
            error.password = `Passwords must contain \n\u2022 a minimum of 1 lower case letter, \n\u2022 1 upper case letter, \n\u2022 1 numeric character \n\u2022 1 special character and \n\u2022 at least 8 characters in length.`;
            error.in_valid = true;
        } else if (userInfo.password !== userInfo.confirmPassword) {
            error.password = 'Password and confirm password does not match.';
            error.in_valid = true;
        };
        setDataError(error);
        return error.in_valid;
    };

    const sendOptOnEmail = async () => {
        try {
            const { email, firstName } = userInfo;
            const { data } = await axios.post('/api/v2/sendOtpOnEmail', { email, firstName });
            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            try {
                const in_valid = validateData();
                if (!in_valid) {
                    setLoading(true);
                    await dispatch(registerNewAccount(userInfo));
                    await sendOptOnEmail();
                    setLoading(false);
                    setStepNumber(2);
                };
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    setDataError({ email_duplication: true });
                }
                setLoading(false);
                console.log("🚀 ~ onSubmit ~ error:", error);
            }
        }
    };

    return (
        <PersonalInfoContainer>
            <HeaderContainer>
                <Heading>Create your account</Heading>
                <SubHeading>Complete the form to create your Ocoord account</SubHeading>
            </HeaderContainer>
            <FormContainer noValidate validated={validated} onSubmit={onSubmit}>
                <FormField>
                    <FormLabel>First Name <span className="text-danger">*</span></FormLabel>
                    <FormInput required onChange={(e) => onChange('firstName', e.target.value)} value={userInfo.firstName} type="text" placeholder="Enter your first name" />
                    <ErrorContainer type="invalid">Required</ErrorContainer>
                </FormField>
                <FormField>
                    <FormLabel>Last Name <span className="text-danger">*</span></FormLabel>
                    <FormInput required onChange={(e) => onChange('lastName', e.target.value)} value={userInfo.lastName} type="text" placeholder="Enter your last name" />
                    <ErrorContainer type="invalid">Required</ErrorContainer>
                </FormField>
                <FormField>
                    <FormLabel>Work Email <span className="text-danger">*</span></FormLabel>
                    <FormInput required onChange={(e) => onChange('email', e.target.value)} value={userInfo.email} type="email" placeholder="Enter your work email" />
                    <ErrorContainer type="invalid">Required</ErrorContainer>
                    {dataError.email &&
                        <CustomErrorContainer className="text-danger">{dataError.email}</CustomErrorContainer>
                    }
                    {dataError.email_duplication &&
                        <CustomErrorContainer className="text-danger">A user with this email address already exists. Please log in <a href={`/signin?email=${encodeURIComponent(userInfo.email)}&nextUrl=account/practiceLocations`}>here</a>.</CustomErrorContainer>
                    }
                </FormField>
                <FormField>
                    <FormLabel>Password <span className="text-danger">*</span></FormLabel>
                    <FormInput required onChange={(e) => onChange('password', e.target.value)} value={userInfo.password} type="password" placeholder="Enter your password" />
                    <ErrorContainer type="invalid">Required</ErrorContainer>
                </FormField>
                <FormField>
                    <FormLabel>Confirm Password <span className="text-danger">*</span></FormLabel>
                    <FormInput required onChange={(e) => onChange('confirmPassword', e.target.value)} value={userInfo.confirmPassword} type="password" placeholder="Confirm your password" />
                    <ErrorContainer type="invalid">Required</ErrorContainer>
                    {dataError.password &&
                        <CustomErrorContainer className="text-danger">{dataError.password}</CustomErrorContainer>
                    }
                </FormField>
                <FormField>
                    <Button disable={loading} type='submit' size="lg" className="w-100 mt-4" variant="success">{loading ? 'Loading...' : 'Next'}</Button>
                    <Button disable={loading} onClick={() => history.goBack()} size="lg" className="w-100 mt-4" variant="light">Cancel</Button>
                </FormField>
            </FormContainer>
        </PersonalInfoContainer>
    )
};

export default PersonalInfo;