import actions from './actionTypes';
import axios from 'axios';
import { history } from '../App';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { apiUrl } from '../helpers';
import { deleteLocationSuccess } from './adminActions';
import { getSafetyOfficer } from './accountActions';
import { openNotificationPanel, openPanel } from './commonActions';

/**
 * @namespace userActions
 * @category Actions
 */

/**
 * @method
 * @memberOf userActions
 * @param {*}
 */
const loginRequest = () => ({
  type: actions.LOGIN_REQUEST
});

/**
 * @method
 * @memberOf userActions
 * @param {*} user
 */
const loginSuccess = (user) => ({
  type: actions.LOGIN_SUCCESS,
  user
});

/**
 * @method
 * @memberOf userActions
 * @param {*}
 */
const requestPassword = () => ({
  type: actions.REQUEST_PASSWORD
});

/**
 * @method
 * @memberOf userActions
 * @param {*}
 */
const requestPasswordSuccess = () => ({
  type: actions.REQUEST_PASSWORD_SUCCESS
});

/**
 * @method
 * @memberOf userActions
 * @param {*}
 */
const requestPasswordFailure = () => ({
  type: actions.REQUEST_PASSWORD_FAILURE
});

/**
 * @method
 * @memberOf userActions
 * @param {*} user
 */
export const loadUserSuccess = (user) => ({
  type: actions.LOAD_USER_SUCCESS,
  user
});

const loadAdminForLocationSuccess = (admins) => ({
  type: actions.LOAD_ADMINS_FOR_LOCATION_SUCCESS,
  admins
});
/**
 * @method
 * @memberOf userActions
 * @param {*}
 */
const loadUserFailure = () => ({
  type: actions.LOAD_USER_FAILURE
});

/**
 *
 *
 * @param {*} dispatch
 * @param {*} user
 */
export const getAdminsForCurrentLocation = () => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    let { data } = await axios.get(apiUrl(`/api/v1/mySupervisors/${currentUser.location._id}`));
    const index = data.map(e => e.email).indexOf(currentUser.email);
    if (index > 0) {
      const removed = data.splice(index, index);
      data = [...removed, ...data];
    };
    await dispatch(loadAdminForLocationSuccess(data));
    return data;
  } catch (error) {
    console.error(error)
  }
};

export const loadUser = (userData) => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    const { data } = await axios.get(apiUrl(`/api/v1/users/${userData._id}`));
    await dispatch(loadUserSuccess(data));
    await dispatch(getSafetyOfficer(currentUser.location._id));
    return data;
  } catch (e) {
    toast.error(`loadUser failed with error: ${e.message}`);
    dispatch(loadUserFailure());
    return false;
  }
};

/**
 * @method
 * @async
 * @memberOf userActions
 * @param {*} userData
 * @returns {history.push} - returns the next page in the app,
 * likely the login page or the next step in registering a practice
 */
export const attemptLogin = (userData, location_state, nextUrl) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const { data } = await axios.post(apiUrl('/api/v1/users/login'), {
      ...userData
    });
    axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
    dispatch(loginSuccess(data.user));
    if (data && data.registrationStep === 4) {
      toast.info(`Resuming registration for ${data.user.email}`);
      return history.push('/registerPractice', {
        currentStep: 4
      });
    }
    dispatch(deleteLocationSuccess());
    let redirectTo = '/';
    if (nextUrl) {
      redirectTo = nextUrl;
    } else {
      redirectTo = location_state.state?.from?.pathname + location_state.state?.from?.search || '/'; // Combine pathname and search (query params)
    }
    return history.push(redirectTo);
  } catch (err) {
    if (err.response && err.response.status === 403) {
      const { data } = err.response;
      return toast.error(data.error);
    };
    if (err.response && err.response.status === 422) {
      return toast.error('Invalid Username or Password');
    };
    Cookies.remove('token');
    localStorage.removeItem('state');
    history.push('/signin');
  }
};

/**
 * @method
 * @async
 * @memberOf userActions
 * @param {*} userData
 */
export const submitRegistration = (userData) => async (dispatch) => {
  try {
    const { data } = await axios.post('/api/v2/users/registerWithCode', userData);
    axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
    if (data && data.isNewUser) {
      dispatch(registerNewAccountSuccess(data));
      return history.push('/registerUser');
    } else {
      toast.success('You are already registered, please login and accept the invite');
      const isLoggedIn = !!Cookies.get('token'); // Check if user is logged in
      if (!isLoggedIn) {
        // Pass redirect path in React Router state
        history.push('/signin?nextUrl=/account/practiceLocations');
      } else {
        return history.push("/account/practiceLocations");
      }
    }
  } catch (err) {
    dispatch(registerNewAccountFailure());
    if (err.response && [402, 401, 403, 422].includes(err.response.status)) {
      // toast.error(err.response.data);
      return err.response.data;
    };
    return toast.error(`error in submitRegistration: There was an error registering this account: ${err}`);
  }
};


/**
 * @method
 * @memberOf userActions
 * @param {*}
 */
const signoutSuccess = () => ({
  type: actions.SIGNOUT_SUCCESS
});

/**
 * @method
 * @async
 * @memberOf userActions
 * @param {*}
 */
export const signOut = () => async (dispatch) => {
  try {
    Cookies.remove('token');
    await dispatch(openPanel(false))
    await dispatch(openNotificationPanel(false))
    await dispatch(deleteLocationSuccess());
    await dispatch(signoutSuccess());
    localStorage.removeItem('state');
    return history.push('/signin');
  } catch (err) { }
};

/**
 * @method
 * @async
 * @memberOf userActions
 * @param {*} message
 */
export const sendMessage = (message) => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser: { _id } }
    } = getState();

    await axios.post(apiUrl(`/api/v1/users/${_id}/supportrequest`), {
      message
    });
  } catch (err) {
    console.log(err);
  }
};

/**
 * @method
 * @memberOf userActions
 * @param {*} user
 */
export const registerNewAccountSuccess = (user) => ({
  type: actions.REGISTER_NEW_ACCOUNT_SUCCESS,
  user
});

/**
 * @method
 * @memberOf userActions
 * @param {*}
 */
const registerNewAccountFailure = () => ({
  type: actions.REGISTER_NEW_ACCOUNT_FAILURE
});

/**
 * @method
 * @memberOf userActions
 * @param {*} user
 */
export const resumeRegistration = (user) => ({
  type: actions.RESUME_REGISTRATION,
  user
});


/**
 * @method
 * @memberOf userActions
 * @param {*} user
 */
export const onboardUserSuccess = (user) => ({
  type: actions.ONBOARD_USER_SUCCESS,
  user
});

/**
 * @method
 * @memberOf userActions
 * @param {*}
 */
export const onboardUserFailure = () => ({
  type: actions.ONBOARD_USER_FAILURE
});

/**
 * @method
 * @async
 * @memberOf userActions
 * @param {*} user
 */
export const onboardUser = (user) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/api/v2/users/onboard/${user._id}`, user);
    axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
    await dispatch(onboardUserSuccess(data));
    return data;
  } catch (error) {
    console.error(`error in onboard: ${error.response}`);
    if (error.response && error.response.status === 409) {
      toast.error('User email already exists');
      return false;
    }
    toast.info(error.response);
    dispatch(onboardUserFailure());
    return false;
  }
};

const updateUserSuccess = (user) => ({
  type: actions.UPDATE_USER_SUCCESS,
  user
});

export const updateOnboardUser = (updatedUser) => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    const { data } = await axios.put(`/api/v2/users/onboard/update/${currentUser._id}`, updatedUser);
    await dispatch(loadUser(data));
    await dispatch(updateUserSuccess(data));
    toast.success('User created successfully');
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * @method
 * @async
 * @memberOf userActions
 * @param {*} user
 */
// LoginFlow
export const registerNewAccount = (user) => async (dispatch) => {
  try {
    let newUser = { ...user };
    if (user._id) delete newUser._id;

    const { data } = await axios.post(apiUrl(`/api/v2/users/register`), newUser);
    axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
    dispatch(registerNewAccountSuccess(data));
    return data;
  } catch (error) {
    if (error.response && error.response.status === 409) {
      toast.error('User email already exists');
      throw error;
    }
    if (error.response && error.response.status === 422) {
      const { user } = error.response.data;
      toast.info(`Resuming registration for ${user.email}`);
      axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
      dispatch(resumeRegistration(user));
      return user;
    } else {
      toast.info(error.response);
      dispatch(registerNewAccountFailure());
      throw false;
    }
  }
};


/**
 * @method
 * @memberOf userActions
 * @param {*}
 */
export const sendMessageSuccess = () => ({
  type: actions.SEND_MESSAGE_SUCCESS
});

/**
 * @method
 * @memberOf userActions
 * @param {*} password
 */
const changePasswordSuccess = (password) => ({
  type: actions.UPDATE_PASSWORD_SUCCESS,
  password
});

/**
 * @method
 * @memberOf userActions
 * @param {*}
 */
const changePasswordFailure = () => ({
  type: actions.UPDATE_PASSWORD_FAILURE
});

// chang needed 
export const changePasswordInside = (userData) => async (dispatch, getState) => {
  try {
    await axios.put(`/api/v2/account/updateUsersPassword`, userData);
    // dispatch(changePasswordSuccess(data));
    toast.success('Password updated successfully');
  } catch (err) {
    console.error('======================================');
    console.error(err.response);
    console.error('======================================');
    dispatch(changePasswordFailure());
    throw err.response ? err.response.data : new Error('Unknown error');
  }
};
/**
 * @method
 * @memberOf userActions
 * @param {*} location
 */
export const changeLocation = (location, practice) => ({
  type: actions.CHANGE_LOCATION,
  location,
  practice
});
