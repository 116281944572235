import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import jwtSimple from "jwt-simple";
import { Spinner, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Input, Button, Checkbox } from '../components';
import OcoordLogo from '../assets/images/logo-icon-flat.svg';
import { attemptLogin, signOut } from '../actions';

const FormContainer = styled(Col)`
  padding: 45px;
  width: 325px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
`;

const LoginHeader = styled.div`
  text-align: center;
  img {
    max-height: 50px;
  }
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  width: 100%;
  p {
    text-align: center;
  }
`;

const StyledLink = styled(Link)`
  margin-bottom: 10px;
  display: block;
`;

/**
 * Login Page
 *
 * @class Login
 * @extends {Component}
 * @category Pages
 * @component
 * @hideconstructor
 */
class Login extends Component {
  state = {
    email: new URLSearchParams(window.location.search).get("email") ? new URLSearchParams(window.location.search).get("email") : '',
    password: '',
    joinCode: new URLSearchParams(window.location.search).get("code") || '',
    isSupperAdmin: false,
    passwordFieldType: 'password',
    loading: false,
    nextUrl: ''
  };

  signOut = () => {
    this.setState({ showPanel: false });
    this.props.dispatch(signOut());
  };

  componentDidMount() {
    const { history, dispatch } = this.props;
    const params = new URLSearchParams(window.location.search);
    const nextUrl = params.get('nextUrl');
    if (nextUrl) {
      this.setState({ nextUrl });
    }
    const data = params.get("data");
    if (Cookies.get('token') && !data) {
      this.signOut();
      history.push('/');
    };
    if (data) {
      const userData = jwtSimple.decode(data, "2%'AD<rB3p/#9MvtD=A'");
      dispatch(attemptLogin({ ...userData, isSupperAdmin: true }, {}));
    }
  };

  handleInputChange = ({ target, key }) => {
    if (key === 'Enter') {
      this.handleSubmit();
    } else {
      this.setState({ [target.name]: target.value });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { dispatch, location } = this.props;
    const { email, password, isSupperAdmin, nextUrl } = this.state;

    this.setState({ loading: true });
    await dispatch(attemptLogin({ email: email.toLowerCase(), password, isSupperAdmin }, location, nextUrl));
    this.setState({ loading: false });
  };

  render() {
    const { email, password, passwordFieldType, loading } = this.state;
    return (
      <FormContainer lg='3' md='6' sm='10'>
        <LoginHeader>
          <img src={OcoordLogo} alt='ocoord-logo' />
          <h2>Sign In to Ocoord</h2>
        </LoginHeader>
        <LoginForm onSubmit={this.handleSubmit}>
          <Input
            name='email'
            label='Username'
            type='text'
            onChange={this.handleInputChange}
            value={email}
            placeholder='Username'
            required
          />
          <Input
            name='password'
            label='password'
            type={passwordFieldType}
            placeholder='Password'
            onChange={this.handleInputChange}
            value={password}
            required
          />
          <div className='d-flex mt-2'>
            <Checkbox onChange={(e) => {
              this.setState({ passwordFieldType: passwordFieldType === 'password' ? 'text' : 'password' });
            }} checked={passwordFieldType === 'text'} />
            <h6 className='mt-1' style={{ color: '#7E818F' }}> Show Password</h6>
          </div>
          <Button disabled={loading} className='my-2' type='submit'>
            {!loading ? 'Sign In' : 'Loading'}
            {loading &&
              <Spinner size='sm' animation="border" variant="secondary" />
            }
          </Button>
        </LoginForm>
        <div className='text-center mt-3'>
          <StyledLink to='/requestpassword'>Forgot your password?</StyledLink>
          {/* <StyledLink to='/register'>Join an existing practice</StyledLink> */}
          <StyledLink to='/registerPractice'>Create a new practice</StyledLink>
        </div>
      </FormContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated
});

export default withRouter(connect(mapStateToProps)(Login));
