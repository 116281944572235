import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { submitRegistration } from '../actions';
import { Alert, Container, Spinner } from 'react-bootstrap';
import InvalidLogo from '../assets/images/invalid-link.svg';

const ExpiredLinkContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px;
    min-width: 325px;
    min-height: 325px;
    background: #ffffff;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
`;

/**
 * Login Page
 *
 * @class Login
 * @extends {Component}
 * @category Pages
 * @component
 * @hideconstructor
 */
class JoinWithCode extends Component {
    state = {
        loading: false,
        error: '',
    };

    componentDidMount() {
        const { history: { location } } = this.props;
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('email');
        const phone = searchParams.get('phone');
        const joinCode = searchParams.get('code');
        const codeHash = searchParams.get('codeHash');
        if (!joinCode) {
            this.setState({ error: 'Join Code not found.', loading: false });
        } else {
            if (email || phone) {
                const { dispatch } = this.props;
                this.setState({ loading: true });
                const userName = email || phone;
                const payload = { joinCode, codeHash, userName: userName.toLowerCase() };
                dispatch(submitRegistration(payload)).then((errorMessage) => {
                    if (errorMessage) {
                        this.setState({ error: errorMessage });
                    }
                    this.setState({ loading: false });
                });
            } else {
                this.setState({ error: 'Invalid Link', loading: false });
            }
        };
    };

    render() {
        const { error, loading } = this.state;
        return (
            loading ? (
                <ExpiredLinkContainer className='text-center my-3 w-100'>
                    <Spinner animation="border" variant="success" />
                </ExpiredLinkContainer>
            ) : (
                <ExpiredLinkContainer className='text-center my-3 px-0 w-100'>
                    <div className='w-100'>
                        <img src={InvalidLogo} alt="" height='300' />
                        <Alert id='error-alert' className='m-4' style={{ maxWidth: '100%' }} variant="danger">
                            <h2 className='w-100 mb-0'>{error}</h2>
                        </Alert>
                    </div>
                </ExpiredLinkContainer>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated
});

export default withRouter(connect(mapStateToProps)(JoinWithCode));