import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MyAccountContainer, SectionTitle, FormLabel, ErrorContainer, FormInput, WhiteButton } from "../Account/Style";
import { Col, Row, Modal, Container, Spinner, Form, Alert } from "react-bootstrap";
import InfoIcon from '../../assets/images/info-green.svg';
import DatePicker from 'react-datepicker';
import { Checkbox } from '../../components/Checkbox';
import axios from 'axios';
import Select from 'react-select';
import { DropDownStyle, states } from "../../helpers";
import { Button, DateRangeInputContainer } from "../../components";
import moment from 'moment';
import Agreement from "../Registration/Agreement";
import { registerPractice } from "../../actions";
import BillingDetails from "./BillingDetails";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const CreateNewPractice = ({ onClose }) => {
    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(false);
    const locationOptions = [{ label: 'One Location', value: 'single' }, { label: 'More than 1 Location', value: 'many' }];
    const [companyInfo, setCompanyInfo] = useState({ locationName: '', practiceName: '', type: '', locationAmount: '', annual_review_date: null, address: '', city: '', state: '', zip: '' });
    const [termAndConditions, setTermAndConditions] = useState(false);
    const [agreementSigned, setAgreementSigned] = useState(false);
    const [locationType, setLocationType] = useState([]);
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({ apiError: false, agreement: false, errorMessage: '' });
    const [isOpenBillingDetails, setIsOpenBillingDetails] = useState(false);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
    const dispatch = useDispatch();
    useEffect(() => {
        getMasterData();
    }, []);

    const getMasterData = async () => {
        try {
            const { data: { payload } } = await axios.get('/api/v2/master/defaultlocationtypes');
            setLocationType(payload);
        } catch (error) {
            console.log("🚀 ~ getMasterData ~ error:", error);
        }
    };
    const onChange = (name, value) => {
        const tempCompanyInfo = { ...companyInfo };
        if (name === 'zip' && value && value.length > 5) {

        } else if (name === 'annual_review_date') {
            tempCompanyInfo[name] = moment(value).format('MM/DD');
            setCompanyInfo(tempCompanyInfo);
        } else {
            tempCompanyInfo[name] = value.toString().trimStart();
            setCompanyInfo(tempCompanyInfo);
        }
    };

    const dummyOnchange = () => { };

    const onSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else if (!agreementSigned) {
            setErrors({ agreement: true, errorMessage: 'Please accept the terms and conditions before proceeding' });
            return;
        } else {
            try {
                setLoading(true);
                setLoading(false);
                setIsOpenBillingDetails(true);
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ onSubmit ~ error:", error);
            }
        };
    };

    const showTermsAndConditions = (e, action) => {
        e.stopPropagation();
        setTermAndConditions(action);
    };
    const handleClose = () => {
        setShow(false);
        onClose(false);
    };
    return (
        // <MyAccountContainer className="p-2">
        <Modal
            centered
            animation={true}
            backdrop="static"
            keyboard={false}
            size='lg' show={show}>
            {loading ?
                <Container className='text-center my-5'>
                    <Spinner animation="border" variant="success" />
                </Container> :
                <>
                    <Modal.Header >
                        <Modal.Title>Create New Practice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            !isOpenBillingDetails ?
                                <>
                                    <Form noValidate validated={validated} onSubmit={onSubmit}>
                                        <Row>
                                            <Col lg='12' md='12' sm='12' className='mb-3'>
                                                <SectionTitle>Company Details</SectionTitle>
                                            </Col>
                                            <Col lg='6' md='6' sm='12'>
                                                <FormLabel>Practice Type <span className="text-danger">*</span></FormLabel>
                                                <Select
                                                    placeholder="Select practice type"
                                                    onChange={(e) => onChange('type', e.value)}
                                                    options={locationType}
                                                    menuPosition="fixed"
                                                    styles={DropDownStyle}
                                                    required={true}
                                                    value={locationType.find(loc => loc.value === companyInfo.type)}
                                                />
                                                <input type="text" value={companyInfo.type} onChange={dummyOnchange} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px' }} />
                                                <ErrorContainer style={{ 'marginTop': '-15px' }} type="invalid">Required</ErrorContainer>
                                            </Col>
                                            <Col lg='6' md='6' sm='12'>
                                                <FormLabel>Location Count <span className="text-danger">*</span></FormLabel>
                                                <Select
                                                    placeholder="Location count"
                                                    onChange={(e) => onChange('locationAmount', e.value)}
                                                    options={locationOptions}
                                                    menuPosition="fixed"
                                                    styles={DropDownStyle}
                                                    required={true}
                                                    value={locationOptions.find(loc => loc.value === companyInfo.locationAmount)}
                                                />
                                                <input type="text" value={companyInfo.locationAmount} onChange={dummyOnchange} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px' }} />
                                                <ErrorContainer style={{ 'marginTop': '-15px' }} type="invalid">Required</ErrorContainer>
                                            </Col>
                                            <Col lg='6' md='6' sm='12'>
                                                <FormLabel>Company Name <span className="text-danger">*</span></FormLabel>
                                                <FormInput onChange={(e) => onChange('practiceName', e.target.value)} required type="text" placeholder="Enter company name" />
                                                <ErrorContainer type="invalid">Required</ErrorContainer>
                                            </Col>
                                            <Col lg='6' md='6' sm='12'>
                                                <FormLabel>First Location Name <span className="text-danger">*</span></FormLabel>
                                                <FormInput onChange={(e) => onChange('locationName', e.target.value)} required type="text" placeholder="Enter location name" />
                                                <ErrorContainer type="invalid">Required</ErrorContainer>
                                            </Col>
                                            <Col lg='12' md='12' sm='12' className='my-3'>
                                                <FormLabel> <img src={InfoIcon} alt="" /> You’ll add your other locations after practice created.</FormLabel>
                                            </Col>
                                            <Col lg='12' md='12' sm='12' className='mb-3'>
                                                <SectionTitle>Location Details</SectionTitle>
                                            </Col>
                                            <Col lg='6' md='6' sm='12'>
                                                <FormLabel>Address <span className="text-danger">*</span></FormLabel>
                                                <FormInput onChange={(e) => onChange('address', e.target.value)} required type="text" placeholder="Enter address" />
                                                <ErrorContainer type="invalid">Required</ErrorContainer>
                                            </Col>
                                            <Col lg='6' md='6' sm='12'>
                                                <FormLabel>Annual Review Date (MM/DD)<span className="text-danger">*</span></FormLabel>
                                                <DateRangeInputContainer className='p-0'>
                                                    <DatePicker
                                                        placeholderText='mm/dd'
                                                        popperPlacement='bottom-end'
                                                        excludeScrollbar={false}
                                                        fixedHeight={true}
                                                        dateFormat="MM/dd"
                                                        required
                                                        value={companyInfo.annual_review_date}
                                                        onChange={(e) => onChange('annual_review_date', e)}
                                                        className="date-input"
                                                        isClearable={false}
                                                    />
                                                </DateRangeInputContainer>
                                                <ErrorContainer type="invalid">Required</ErrorContainer>
                                            </Col>
                                            <Col lg='6' md='6' sm='12'>
                                                <FormLabel>City <span className="text-danger">*</span></FormLabel>
                                                <FormInput onChange={(e) => onChange('city', e.target.value)} required type="text" placeholder="Enter city" />
                                                <ErrorContainer type="invalid">Required</ErrorContainer>
                                            </Col>
                                            <Col lg='6' md='6' sm='12'>
                                                <FormLabel>State <span className="text-danger">*</span></FormLabel>
                                                <Select
                                                    value={states.find(loc => loc.value === companyInfo.state)}
                                                    placeholder="Choose State"
                                                    onChange={(e) => onChange('state', e.value)}
                                                    options={states}
                                                    menuPosition="fixed"
                                                    styles={DropDownStyle}
                                                    required={true}
                                                />
                                                <input type="text" value={companyInfo.state} onChange={dummyOnchange} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px' }} />
                                                <ErrorContainer style={{ 'marginTop': '-15px' }} type="invalid">Required</ErrorContainer>
                                            </Col>
                                            <Col lg='6' md='6' sm='12'>
                                                <FormLabel>Zip Code <span className="text-danger">*</span></FormLabel>
                                                <FormInput value={companyInfo.zip} onChange={(e) => onChange('zip', e.target.value)} required type="text" placeholder="Enter zip code" />
                                                <ErrorContainer type="invalid">Required</ErrorContainer>
                                            </Col>
                                            <Col lg='12' md='12' sm='12' className='my-3'>
                                                <div className="d-flex">
                                                    <Checkbox
                                                        onChange={() => { setAgreementSigned(!agreementSigned); setErrors({ agreement: false, errorMessage: '' }) }}
                                                        name='agreementSigned'
                                                        checked={agreementSigned}
                                                    />
                                                    I have read and agree to the <span onClick={(e) => showTermsAndConditions(e, !termAndConditions)} className="text-success cursor-pointer">Terms of Service and Privacy Policy</span>
                                                </div>
                                                <ErrorContainer type="invalid">Required</ErrorContainer>
                                            </Col>
                                        </Row>
                                        {errors.agreement &&
                                            <Alert id='error-alert' className='my-0 w-100 text-center mx-4' style={{ 'maxWidth': '100%' }} variant="danger">
                                                <p className='w-100 mb-0'>
                                                    {errors.errorMessage}
                                                </p>
                                            </Alert>
                                        }
                                        <Row className="mt-4">
                                            <Col lg='6' md='6' sm='6'>
                                                <WhiteButton className='mt-0' type='button' onClick={() => handleClose()}>Cancel</WhiteButton>
                                            </Col>
                                            <Col lg='6' md='6' sm='6'>
                                                <Button disabled={loading} className='mt-0' type='submit' size="lg" variant="success">{loading ? 'Loading...' : 'Next'}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    {termAndConditions && <Agreement onClose={setTermAndConditions} />}
                                </>
                                :
                                <Elements stripe={stripePromise}>
                                    <BillingDetails companyInfo={companyInfo} />
                                </Elements>
                        }

                    </Modal.Body>
                </>
            }
        </Modal>
        // </MyAccountContainer>
    );
};

export default CreateNewPractice;