import { useState } from "react";
import { useSelector } from "react-redux";
import { CustomAlert, SectionBlock, SectionHeading, MyAccountContainer } from "../Account/Style";
import AlertInfo from '../../assets/my-account/alert_info.svg';
import Upload from '../../assets/my-account/upload.svg';
import Info from '../../assets/images/info.svg';
import { Table, Button } from "react-bootstrap";

const EducationAndTraining = () => {
    const user = useSelector((state) => state.user.currentUser);
    const [recordLoading, setRecordLoading] = useState(false);

    return (
        <MyAccountContainer>
            <CustomAlert id='error-alert' className='my-2 py-2 font info w-100 d-flex' style={{ 'maxWidth': '100%' }} variant="secondary">
                <img src={AlertInfo} alt='AlertInfo' />
                <h3 className='w-100 align-self-center  mb-0 ml-2'>View and manage training completions and additional educational certificates.</h3>
            </CustomAlert>
            <div >
                <SectionBlock className="mt-4">
                    <SectionHeading className="flex-wrap">Education & Training Records
                        <Button type='button' variant='success'
                            className='px-4 cursor-pointer mb-2'>
                            <img className=' mr-3' src={Upload} alt="" />
                            Upload New Document
                        </Button>
                    </SectionHeading>
                    {recordLoading ?
                        <Col lg={12} style={{ minHeight: '30px' }} className='text-center'>
                            <Spinner animation="border" variant="success" />
                        </Col>
                        :
                        <div className="responsive-table">
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='pl-4'>Date Completed</th>
                                        <th>Valid Through</th>
                                        <th>Type</th>
                                        <th>Completion Certificate</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr >
                                        <td className=' pl-4'>02/05/25</td>
                                        <td>02/05/26</td>
                                        <td>
                                            <img className=' mr-3' src={Info} alt="" />
                                            Annual OSHA Training
                                        </td>
                                        <td>
                                            <img className=' mr-3' src={Info} alt="" />
                                            <a href="">Completion Certificate</a></td>
                                    </tr>
                                    {/* <tr >
                                    <td className='w-25 pl-4'>02/05/25</td>
                                    <td className='w-25'>02/05/26</td>
                                    <td className='w-25'>
                                        <img className=' mr-3' src={Info} alt="" />
                                        Annual OSHA Training
                                    </td>
                                    <td className='w-25'>
                                        <img className=' mr-3' src={Info} alt="" />
                                        <a href="">Completion Certificate</a>
                                    </td>
                                    <td className='w-25'>
                                        <img className=' mr-3' src={Info} alt="" />
                                        <a href="">Completion Certificate</a>
                                    </td>
                                </tr> */}

                                </tbody>
                            </Table>
                        </div>
                    }
                </SectionBlock>
            </div>
        </MyAccountContainer>
    );
};

export default EducationAndTraining;